<template>
  <v-container>
    <div class="d-flex flex-row" rounded color="grey lighten-3">
      <!-- ---------------------- Content ---------------------- -->
      <div class="d-flex flex-column flex-1 align-center pa-4">
        <v-img class="d-flex d-md-none" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fportal%2Fsmall-banner.png?alt=media&token=1d817e5c-dc26-4c45-aa51-31ab92973a0b"></v-img>
        <div class="g-font renedian-red--text font-size-18 mt-6">ACCESSING YOUR RIDER PORTAL</div>
        <!-- ------------ Authenticate Link ------------ -->
        <div v-if="user" class="d-flex flex-column align-center mt-6">
          <v-alert type="success" outlined>
            Successfully logged in
          </v-alert>
        </div>
        <div v-else-if="authenticating" class="d-flex flex-column align-center">
          <div>Authenticating your email</div>
          <v-progress-circular indeterminate color="primary" class="mt-6"></v-progress-circular>
        </div>
        <!-- ------------ Request Access ------------ -->
        <div v-else class="d-flex flex-column align-center">
          <div>Follow these easy steps to access your secure Rider Portal:</div>
          <ul class="my-6 list width-80">
            <li>Enter the email address that you used to register with at the time of booking</li>
            <li>An unique link will be emailed to you</li>
            <li>Click on the link to log in</li>
          </ul>
          <v-alert v-if="infoMessage" type="info" outlined class="mb-8">
            <strong>Link Expired:</strong> {{ infoMessage }}
          </v-alert>
          <v-alert v-if="errorMessage" type="error" outlined class="mb-8">
            <strong>There was a problem</strong> {{ errorMessage }}
          </v-alert>
          <v-alert v-if="emailSent" type="success" outlined class="mb-8">
            <strong>Email Sent Successfully</strong> If you don't see the email in your inbox, check your spam folder.
          </v-alert>
          <div class="d-flex flex-column width-100 align-center mb-12">
            <v-text-field v-model="email" label="Email you booked with" class="width-80" :disabled="emailSending" outlined hide-details></v-text-field>
            <!-- <v-btn color="primary" @click="sendEmail()" :loading="emailSending" class="mt-4">CLICK HERE TO GET YOUR LINK</v-btn> -->
            <v-btn color="primary" @click="sendEmail()" :loading="emailSending" :disabled="emailSent" class="mt-4">SEND YOUR LOGIN LINK</v-btn>
          </div>
        </div>
      </div>
      <div class="d-none d-md-flex flex-column flex-1" style="background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fportal%2Fwide-mockup.png?alt=media&token=f965be0e-5231-4520-84b6-6cfb3f84f517'); background-size: cover">
        <!-- -->
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';

export default {
  name: 'PortalLogin',
  props: ['user', 'environment'],
  data() {
    return {
      // ------------ Common ------------
      infoMessage: '',
      errorMessage: '',
      // ------------ Request Access ------------
      email: '',
      emailSending: false,
      emailSent: false,
      // ------------ Authenticate Link ------------
      authenticating: false,
    };
  },
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const storedEmail = window.localStorage.getItem('emailForSignIn');
    const email = urlParams.get('email') || storedEmail;

    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
      if (!email) {
        this.infoMessage = 'Email is required to complete sign-in. Please provide your email again.';
        this.email = storedEmail || '';
        return;
      }
      this.authenticating = true;
      try {
        const auth = getAuth();
        await signInWithEmailLink(auth, email, window.location.href);
        window.localStorage.removeItem('emailForSignIn');
        this.$router.push({ path: '/portal' }); // Redirect to the portal page after successful login
      } catch (error) {
        if (error.code === 'auth/invalid-action-code') {
          this.infoMessage = 'The sign-in link has expired or is invalid. Please request a new sign-in email.';
        } else {
          this.errorMessage = 'Failed to complete sign-in. Please try again.';
        }
        console.error('Error completing sign-in:', error);
      } finally {
        this.authenticating = false;
      }
    }
  },
  methods: {
    async sendEmail() {
      this.emailSent = false;
      this.infoMessage = '';
      this.errorMessage = '';
      
      if (!this.email) {
        this.errorMessage = 'Please enter your email address.';
        return;
      }
      
      if (!this.isValidEmail(this.email)) {
        this.errorMessage = 'Please enter a valid email address.';
        return;
      }

      let emailSchedulerEndpoint = this.production() ?
        'https://northamerica-northeast1-renedian-production.cloudfunctions.net/emailScheduler' :
        'https://northamerica-northeast1-renedian-development.cloudfunctions.net/emailScheduler';
      // ------------ Email Portal Link ------------
      let fields = {
        portal_link: this.production()
          ? 'https://renedian-production.web.app/portal/login'
          : 'https://dev.renedian.com/portal/login'
      }
      this.emailSending = true;
      axios.post(emailSchedulerEndpoint, {
        to: this.email,
        // bcc: ['webmaster@renedian.com','info@renedian.com','piet@renedian.com'],
        bcc: ['webmaster@renedian.com'],
        from: 'info@renedian.com',
        template: 'portal-link',
        fields: fields,
        schedule: 'now',
        status: 'pending',
      }).then((response) => {
        this.emailSent = true;
        this.emailSending = false;
        // wait for 30 seconds then clear the email sent message
        setTimeout(() => {
          this.emailSent = false;
        }, 30000);
      }).catch((error) => {
        this.errorMessage = 'Failed to send email. Please try again.';
        this.emailSending = false;
      });
    },
    isValidEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  }
};
</script>
<style lang="scss" scoped>
  .width-100 {
    width: 100%;
  }
  .width-80 {
    width: 80%;
  }
  .list {
    list-style-type: disc;
    padding-left: 50px;
    li {
      margin-bottom: 10px;
    }
  }
</style>