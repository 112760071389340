<template>
  <v-container>
    <div id="app">
      <v-form ref="waiverForm">
        <v-card class="pa-6">
          <!-- Waiver Text with Paragraphs -->
          <div ref="waiverContent" class="waiver">
            <!-- Header Logo -->
            <div class="waiver-centered">
              <img src="@/assets/images/renedian-logo.png" class="waiver-logo">
            </div>
            
            <!-- Waiver Intro -->
            <div class="waiver-section waiver-centered">
              <p class="waiver-paragraph waiver-bold-text">PLEASE READ THIS DOCUMENT THOROUGHLY AS IT WILL AFFECT YOUR LEGAL RIGHTS</p>
              <p class="waiver-paragraph waiver-bold-text">RELEASE OF LIABILITY, WAIVER OF CLAIMS, ASSUMPTION OF RISKS AND INDEMNITY AGREEMENT</p>
              <p class="waiver-paragraph waiver-bold-text">(hereinafter referred to as the "Release Agreement")</p>
              <p class="waiver-paragraph waiver-bold-text">BY SIGNING THIS DOCUMENT YOU WILL WAIVE CERTAIN LEGAL RIGHTS, INCLUDING THE RIGHT TO SUE OR CLAIM COMPENSATION FOLLOWING AN ACCIDENT</p>
              <p class="waiver-emphasis-text">PLEASE READ CAREFULLY!</p>
            </div>

            <!-- Paragraph 1 -->
            <div class="waiver-section">
              <p class="waiver-paragraph">I (the "<span class="waiver-bold-text">Motorcyclist</span>") sign on {{ fullDate }} this RELEASE AND WAIVER OF LIABILITY (the "<span class="waiver-bold-text">Release</span>") in favour of Renedian Adventures Ltd. and all related or associated companies and their respective owners, directors, officers, employees, agents, representatives, instructors, guides, contractors, subcontractors, sponsors, successors and assigns (hereinafter collectively referred to as the "<span class="waiver-bold-text">Releasees</span>")</p>
              <p class="waiver-paragraph">In this Release, the term "<span class="waiver-bold-text">Motorcycling</span>" shall include, but is not limited to: the rental and use of motorcycles, the rental and use of related safety and personal protective equipment, the use of my personal motorcycle, and all related activities, services, and use of facilities, equipment, and/or terrain either provided by or arranged by the Releasees, including guided and self-guided programs, and including the provision of accommodations and food/beverage selections.</p>
            </div>

            <!-- Paragraph 2 -->
            <div class="waiver-section">
              <p class="waiver-section-heading">ACKNOWLEDGEMENT - SAFETY</p>
              <p class="waiver-section-subheading">THE USE OF AN APPROVED HELMET IS MANDATORY</p>
              <p class="waiver-paragraph">I acknowledge that I have been advised I must, and I will, wear the appropriate safety or protective equipment while participating in Motorcycling, including helmets and other protective gear. Using proper safety equipment may be mandatory under applicable governmental law, and I am aware that the use of the Motorcycle is subject to applicable governmental motor vehicle regulations.</p>
              <p class="waiver-paragraph">I am aware that the physical exertion required by Motorcycling and forces exerted on the body can activate or aggravate pre- existing injuries, conditions, symptoms, or congenital defects. I have been advised to seek medical advice if I know or suspect that my physical condition may be incompatible with Motorcycling.</p>
              <p class="waiver-paragraph">I acknowledge that I will not be under the influence of drugs and/or alcohol while participating in Motorcycling.</p>
              <v-checkbox v-model="waiver.acknowledgement.safety" label="I confirm and acknowledge having read and understood the above" />
            </div>

            <!-- Paragraph 3 -->
            <div class="waiver-section">
              <p class="waiver-section-heading">DESCRIPTION AND ASSUMPTION OF RISKS</p>
              <p class="waiver-paragraph">There are many potential <span class="font-weight-bold">RISKS</span>, <span class="font-weight-bold">DANGERS</span> and <span class="font-weight-bold">HAZARDS</span> associated with Motorcycling, including the <span class="font-weight-bold">RISK OF SEVERE INJURY OR DEATH</span>.</p>
              <p class="waiver-paragraph">The roads and pathways used for Motorcycling can be uncontrolled, unmarked, in poor condition, and not inspected, and involves many risks, dangers and hazards. The Releasees make no representations as to whether the roadways are safe.</p>
              <p class="waiver-paragraph">Risks, dangers and hazards associated with Motorcycling may include, <span class="font-weight-bold">but are not limited to</span>:</p>
              <p class="waiver-paragraph">Accidents which occur during transportation to and from the location of participation; Rockfalls, icefall, avalanches of snow, ice and rock triggered by natural forces, by persons travelling through the area or other causes; extreme variation in road conditions and terrain, including hidden or exposed holes or depressions, cracks, uneven surfaces, gravel roads, ice, snow, water or slippery conditions; falls, trips and slips; limited visibility; variable, severe or rapidly changing weather with little or no warning including storms, high winds and whiteouts; dangerous and unpredictable road conditions; hypothermia; frostbite; hyperthermia; dehydration; loss of balance or control and falls, particularly on steep, slippery, uneven or difficult terrain; errors of judgment; lack of preparation; inadequate, inappropriate, improperly prepared or adjusted equipment; equipment failure, malfunction or loss; fires (including burns and damage to property therefrom) loss or damage to personal property; failure to act safely or within one's ability or within a designated area; impact or collision with vehicles, other persons, or objects; encounters with domestic or wild animals; animal bites; becoming lost, going off route or becoming separated from one's party; dangerous driving conditions; civil strife, crime; travel over poor roads; and infectious and communicable disease, including but not limited to COVID-19, contracted through viruses, bacteria, parasites, and fungi, which may be transmitted through direct or indirect contact or the negligence of other persons.</p>
              <p class="waiver-paragraph">Communication, including cellular reception as well as satellite reception, in the areas where Motorcycling occurs may not be reliable or available, and it may be difficult or impossible to communicate with the outside from within such areas, and in the event of an accident, rescue and medical treatment may not be readily available or available.</p>
              <p class="waiver-paragraph">I am aware that participation in Motorcycling also <span class="font-weight-bold">MAY INCLUDE RISKS INCLUDING NEGLIGENCE ON THE PART OF THE RELEASEES. I UNDERSTAND THAT NEGLIGENCE INCLUDES FAILURE ON THE PART OF THE RELEASEES TO TAKE REASONABLE STEPS TO SAFEGUARD OR PROTECT ME FROM THE RISKS, DANGERS AND HAZARDS OF PARTICIPATING IN OUTDOOR ACTIVITIES. I FREELY ACCEPT AND FULLY ASSUME ALL SUCH RISKS, DANGERS AND HAZARDS AND THE POSSIBILITY OF PERSONAL INJURY, DEATH, PROPERTY DAMAGE AND LOSS RESULTING THEREFROM</span>.</p>
              <v-checkbox v-model="waiver.acknowledgement.risks" label="I confirm and acknowledge having read and understood the above" />
            </div>

            <!-- Paragraph 4 -->
            <div class="waiver-section">
              <p class="text-h6">RELEASE OF LIABILITY, WAIVER OF CLAIMS AND INDEMNITY AGREEMENT</p>
              <p>In consideration of the RELEASEES agreeing to my participation in Motorcycling, I do hereby freely, voluntarily, and without duress agree as follows:</p>
              <ol>
                <li class="ml-6"><span class="font-weight-bold">TO WAIVE ANY AND ALL CLAIMS</span> that I have or may in the future have against the RELEASEES <span class="font-weight-bold">AND TO RELEASE THE RELEASEES</span> from any and all liability for any loss, damage, expense or injury, including death, that I may suffer or that my next of kin may suffer, as a result of my participation in Motorcycling, DUE TO ANY CAUSE WHATSOEVER, INCLUDING NEGLIGENCE, BREACH OF CONTRACT, OR BREACH OF ANY STATUTORY OR OTHER DUTY OF CARE ON THE PART OF THE RELEASEES. I UNDERSTAND THAT NEGLIGENCE INCLUDES FAILURE ON THE PART OF THE RELEASEES TO TAKE REASONABLE STEPS TO SAFEGUARD OR PROTECT ME FROM THE RISKS, DANGERS AND HAZARDS OF PARTICIPATING IN THE RENTAL OPPORTUNITY INCLUDING THE RISKS REFERRED TO ABOVE;</li>
                <li class="ml-6">I agree TO HOLD HARMLESS AND INDEMNIFY THE RELEASEES from any and all liability for any property damage or personal injury to any third party resulting from my participation in Motorcycling, as well as any loss of or damage to personal property left in or on the Motorcycle.</li>
                <li class="ml-6">I agree TO HOLD HARMLESS AND INDEMNIFY THE RELEASEES from any and all expenses incurred by the Releasees, including legal fees and/or settlement payments and/or Court or Tribunal awards, as a result of legal or administrative actions brought by myself (or brought on behalf of an infant, if I am executing this Release as the parent/guardian on behalf the said infant participant of Motorcycling).</li>
                <li class="ml-6">Should I (or the minor if I am executing this Release as the parent/guardian on behalf of the said minor participant) require first aid or emergency medical treatment as a result of accident, illness or any medical issue while participating in Motorcycling, I consent to such treatment as deemed necessary by the Releasees.</li>
                <li class="ml-6">Should I (or the minor if I am executing this Release as the parent/guardian on behalf of the said minor participant) require non-scheduled or emergency evacuation, rescue, first aid or medical treatment, I agree that I will bear all costs and expenses associated with such non-scheduled or emergency evacuation, rescue, first aid or medical treatment, and these costs and expenses will not be covered by the Releasees.</li>
                <li class="ml-6">I agree TO HOLD HARMLESS AND INDEMNIFY THE RELEASEES from any and all liability on account of any first aid, treatment or services rendered in connection with my participation in Motorcycling.</li>
                <li class="ml-6">I understand that the Releasees do not assume any responsibility for or obligation to provide financial assistance or other assistance, included but not limited to medical, health or disability insurance.</li>
                <li class="ml-6">I certify that no qualified medical professional has advised me to not participate in Outdoor Activities.</li>
                <li class="ml-6">This Release shall be effective and binding upon my heirs, next of kin, executors, administrators, assigns and representatives, in the event of my death of incapacity;</li>
                <li class="ml-6">This Release and any rights, duties and obligations as between the parties to this Release shall be governed by and interpreted solely in accordance with the laws of the Province of Alberta and no other jurisdiction; and</li>
                <li class="ml-6">Any litigation involving the parties to this Release shall be brought solely within the Province of Alberta and shall be within the exclusive jurisdiction of the Courts of the Province of Alberta.</li>
              </ol>
              <v-checkbox v-model="waiver.acknowledgement.indemnity" label="I confirm and acknowledge having read and understood the above" />
            </div>

            <!-- Paragraph 4 -->
            <div class="waiver-section">
              <p class="text-h6">PHOTO/VIDEO RELEASE</p>
              <p class="waiver-paragraph">I understand that during Motorcycling I may be photographed or videotaped and I consent to such photographs and/or video taken of me being used for advertising, promotional or marketing purposes, whether online, in print, on the Releasees' social media or anywhere else where they choose to use such material.</p>
              <v-checkbox v-model="waiver.acknowledgement.release" label="I confirm and acknowledge having read and understood the above" />
            </div>

            <!-- Paragraph 5 -->
            <div class="waiver-section">
              <p class="text-h6">GENERAL</p>
              <p>If any portion of this agreement shall be held to be invalid or unenforceable, such provision shall be stricken and the remainder of the agreement shall remain in full force and effect to accomplish the intent and purpose of the parties. The parties agree to negotiate the severed provision to bring it within the applicable legal requirements to the extent possible.</p>
              <p>In entering into this Release, I am not relying on any oral or written representations or statements made by the Releasees with respect to the safety of participating in Motorcycling, other than what is set forth in this Release.</p>
            </div>

            <!-- Paragraph 6 -->
             <div class="waiver-section">
              <p class="text-h6">CONFIRMATION AND ACKNOWLEDGEMENT</p>
              <p><span class="font-weight-bold">I confirm that I have read and understand this Release Agreement and by signing it voluntarily I am agreeing to abide by these terms. I am aware that by signing this Release Agreement I am waiving certain legal rights which I or my heirs, executors, administrators, assigns, and representatives may have against the Releasees.</span></p>
             </div>

            <!-- Paragraph 7 -->
            <div class="waiver-section">
              <p><span class="font-weight-bold">BY SIGNING THIS DOCUMENT, WHETHER IN ELECTRONIC FORMAT OR OTHERWISE, I ACKNOWLEDGE, AGREE AND CERTIFY AS FOLLOWS:</span></p>
              <p><span class="font-weight-bold ml-6">a. I HAVE READ AND REVIEWED THE RELEASE AGREEMENT AND FULLY UNDERSTAND AND AGREE TO ITS TERMS;</span></p>
              <p><span class="font-weight-bold ml-6">b. THE ABOVE INFORMATION IS TRUE AND CORRECT IN ALL RESPECTS AS OF THE DATE AND TIME THAT I HAVE COMPLETED THE WAIVER;</span></p>
              <p><span class="font-weight-bold ml-6">c. I AM AWARE THAT BY SIGNING THIS RELEASE AGREEMENT I AM WAIVING CERTAIN LEGAL RIGHTS WHICH I OR MY HEIRS, EXECUTORS, ADMINISTRATORS, ASSIGNS, AND REPRESENTATIVES MAY HAVE AGAINST THE RELEASEES; AND</span></p>
              <p><span class="font-weight-bold ml-6">d. I HAVE THE POWER AND AUTHORITY TO AGREE TO THE RELEASE AGREEMENT.</span></p>
            </div>
            
            <!-- Signature Pad -->
            <div class="waiver-section">
              <p>Please sign below:</p>
              <div v-if="!waiver.complete" class="d-flex flex-row">
                <VueSignaturePad
                  class="signaturePadCanvas"
                  width="500px"
                  height="150px"
                  ref="signaturePad"
                  @beginStroke="onBegin"
                  @endStroke="onEnd"
                />
                <div class="d-flex flex-column ml-1">
                  <v-btn icon @click="undoSignature()"><v-icon>mdi-undo</v-icon></v-btn>
                  <v-btn icon @click="clearSignature()"><v-icon>mdi-eraser</v-icon></v-btn>
                </div>
              </div>
              <div v-else>
                <img
                  :src="waiver.signatory.signatureData"
                  alt="Signature"
                  style="width:500px; height:150px;"
                />
                <v-btn icon @click="clearSignature()"><v-icon>mdi-eraser</v-icon></v-btn>
              </div>
            </div>

            <!-- Signatory Information -->
            <div class="waiver-section">
              <v-text-field v-model="waiver.signatory.name" label="Full Name" outlined></v-text-field>
              <v-text-field v-model="waiver.signatory.date" label="Date" outlined disabled :value="fullDate"></v-text-field>
            </div>
          </div>

          {{ waiver }}

          <!-- Submit and Export as PDF -->
          <v-card-actions>
            <v-btn color="primary" @click="exportToPdf">Submit & Export as PDF</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>
  </v-container>
</template>

<script>
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
// import html2pdf from 'html2pdf.js';
import { DateTime } from 'luxon';

export default {
  data() {
    return {
      waiver: {
        complete: false,
        acknowledgement: {
          safety: false,
          risks: false,
          indemnity: false,
          release: false,
        },
        signatory: {
          name: '',
          date: '',
          signatureData: null,
        }
      }
    };
  },
  methods: {
    saveSignature() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.waiver.signatory.signatureData = data;
      console.log("Signature saved:", this.waiver.signatory.signatureData);
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
      this.waiver.signatory.signatureData = null;
      console.log("Signature cleared");
    },
    undoSignature() {
      this.$refs.signaturePad.undoSignature();
    },
    onBegin() {
      console.log("Signature started");
    },
    onEnd() {
      // Save the signature data whenever the user stops drawing
      if (this.$refs.signaturePad) {
        this.waiver.signatory.signatureData = this.$refs.signaturePad.saveSignature().data;
      }
    },
    async exportToPdf() {
      // Validate the form
      // if (!this.canSubmit) {
      //   alert('Please complete all acknowledgments, enter your name, and provide a signature before submitting.');
      //   return;
      // }

      // Save the signature data if not already saved
      if (this.$refs.signaturePad && !this.waiver.signatory.signatureData) {
        this.waiver.signatory.signatureData = this.$refs.signaturePad.saveSignature().data;
      }

      // Wait for DOM to update
      await this.$nextTick();

      const element = this.$refs.waiverContent;

      // Configure html2pdf options
      const opt = {
        margin: 10, // in mm
        filename: 'waiver.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          useCORS: true,
          allowTaint: false,
          scrollX: 0,
          scrollY: -window.scrollY,
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait',
        },
        pagebreak: { mode: ['css', 'legacy'] },
      };

      // Generate and save the PDF
      html2pdf().set(opt).from(element).save();
    },
  },
  computed: {
    fullDate() {
      return DateTime.now().toLocaleString(DateTime.DATE_FULL);
    },
    allAcknowledged() {
      return Object.values(this.waiver.acknowledgement).every(Boolean);
    },
    canSubmit() {
      return this.allAcknowledged && this.waiver.signatory.name && this.waiver.signatory.signatureData;
    },
  }
};
</script>

<style>
.signaturePadCanvas {
  border: 1px solid #000;
  border-radius: 5px;
}
.page-break {
  page-break-before: always;
  break-before: page;
}
.waiver-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.waiver-section {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.waiver {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}
.waiver .waiver-logo {
  width: 200px;
}
.waiver .waiver-paragraph {
  font-size: 16px;
  margin-bottom: 16px;
}
.waiver .waiver-bold-text {
  font-weight: bold;
}
.waiver .waiver-emphasis-text {
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  margin: 20px 0;
}
.waiver .waiver-section-heading {
  font-size: 24px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 16px;
}
.waiver .waiver-section-subheading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}
</style>
