<template>
  <v-card>
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-title>
      <v-btn icon class="mr-2" @click="go('AdminTours')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span v-if="!loading && model && model.id && model.name">{{ model.name }}</span>
    </v-card-title>
    <v-alert v-if="tourOverrides.length" text tile type="info" class="mb-0">
      This tour has changes which override the original template within: {{ tourOverrides.join(', ') }}
    </v-alert>
    <!-- <v-alert text tile type="warning" class="mb-0">
      This page is under construction.
    </v-alert> -->
    <v-alert v-if="success" type="success" class="mx-3" dismissible>{{ success }}</v-alert>
    <v-alert v-if="error" type="error" class="mx-3" dismissible>{{ error }}</v-alert>
    <v-card-text v-if="!loading && model" class="pb-0">
      <v-tabs v-if="!loading" v-model="tab" show-arrows>
        <v-tab>Details</v-tab>
        <v-tab :disabled="model.schedule.complete.range.length != 2">Itinerary</v-tab>
        <v-tab v-if="model.id" :disabled="model.schedule.complete.range.length != 2">Bookings</v-tab>
        <v-tab :disabled="model.schedule.complete.range.length != 2">Crew</v-tab>
        <v-tab :disabled="model.schedule.complete.range.length != 2">
          <v-badge v-if="model.schedule.complete.range.length == 2 && model.vehicles.length == 0" dot color="red accent-4">
            Vehicles
          </v-badge>
          <span v-else>Vehicles</span>
        </v-tab>
        <v-tab :disabled="model.schedule.complete.range.length != 2">Actions</v-tab>
        <v-tab :disabled="model.schedule.complete.range.length != 2">Checklists</v-tab>
        <v-tab :disabled="model.schedule.complete.range.length != 2">Manifests</v-tab>
        <v-tab :disabled="model.schedule.complete.range.length != 2">Vendors</v-tab>
        <v-tab v-if="model.id" :disabled="model.schedule.complete.range.length != 2">Logs</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab">
        <!-- ---------------------- Details ---------------------- -->
        <v-tab-item>
          <div class="d-flex flex-row">
            <div class="flex-1 ma-4">
              <div class="overline">Basic</div>
              <v-select 
                v-model="selectedTemplate"
                label="Template"
                :items="templates"
                item-text="details.name"
                return-object
                @change="changeTemplate()"
              ></v-select>
              <v-alert
                v-if="model.schedule.complete.range.length == 2 && !isTemplateCurrent"
                icon="mdi-information"
                text
                type="info"
              >
                <div class="d-flex flex-column">
                  There is a newer version of the template available.
                  <div class="d-flex flex-row justify-end mt-2">
                    <v-btn @click="reloadDialog = true" color="info" small outlined>
                      Reload
                    </v-btn>
                  </div>
                </div>
              </v-alert>
              <template v-if="model.template">
                <v-select 
                  v-model="model.status"
                  label="Status"
                  :items="status_types"
                ></v-select>
                <v-text-field label="Tour Code" v-model="model.tourCode" :disabled="true"></v-text-field>
                <div class="d-flex flex-row align-center">
                  <div class="overline">Details</div>
                  <v-spacer></v-spacer>
                  <v-btn v-if="editDetails" @click="editDetails = false" small icon><v-icon small>mdi-check</v-icon></v-btn>
                  <v-btn v-else small icon  @click="editDetails = true"><v-icon small>mdi-pencil</v-icon></v-btn>
                </div>
                <ul v-if="!editDetails">
                  <li>Duration: {{ model.details.duration }} days</li>
                  <li>Spots: {{ model.details.spots }} riders</li>
                  <li>Base Price: C${{ model.details.pricing.base }}</li>
                  <li>Solo Supplement: C${{ model.details.pricing.solo }}</li>
                  <li>Passenger Supplement: C${{ model.details.pricing.passenger }}</li>
                  <li>Distance: {{ model.details.distance }} km</li>
                  <li>Difficulty: {{ model.details.difficulty.gravel }}% gravel</li>
                  <li>Excursion: {{ model.excursion.available == true ? 'Yes' : 'No' }}</li>
                  <li>Support Van: {{ model.details.supportVan == true ? 'Yes' : 'No' }}</li>
                </ul>
                <template v-else>
                  <v-text-field label="Duration" :disabled="true" v-model="model.details.duration" suffix="days" type="number"></v-text-field>
                  <v-text-field label="Spots" v-model="model.details.spots" suffix="riders" type="number"></v-text-field>
                  <v-text-field label="Base Price" prefix="$" v-model="model.details.pricing.base" suffix="CAD" type="number"></v-text-field>
                  <v-text-field label="Solo Supplement" prefix="$" v-model="model.details.pricing.solo" suffix="CAD" type="number"></v-text-field>
                  <v-text-field label="Passenger Supplement" prefix="$" v-model="model.details.pricing.passenger" suffix="CAD" type="number"></v-text-field>
                  <v-text-field label="Distance" v-model="model.details.distance" suffix="km" type="number"></v-text-field>
                  <v-subheader class="text-caption pl-0">Difficulty (gravel {{ model.details.difficulty.gravel }}%)</v-subheader>
                  <v-slider v-model="model.details.difficulty.gravel" class="px-5 mt-3" :max="100" :min="0" thumb-label></v-slider>
                  <v-checkbox v-model="model.details.supportVan" label="Support Van Available"></v-checkbox>
                </template>
              </template>
            </div>
            <v-divider vertical></v-divider>
            <div class="flex-1 ma-4 " v-if="model.template">
              <div class="overline">Schedule</div>
              <v-date-picker
                v-model="model.schedule.primary.range"
                show-adjacent-months
                no-title
                full-width
                range
                @input="scheduleChange()"
                :events="calendarEvents"
                event-color="orange darken-3"
              ></v-date-picker>
              <template v-if="model.schedule.primary.range.length == 2">
                <div class="text-caption orange--text text--darken-2 font-weight-bold mt-1">Primary</div>
                {{ model.schedule.primary.start | formatDate }} - {{ model.schedule.primary.finish | formatDate }}
              </template>
              <template v-if="model.schedule.excursion.range.length == 2">
                <div class="text-caption orange--text text--darken-2 font-weight-bold mt-1">Excursion</div>
                {{ model.schedule.excursion.start | formatDate }} - {{ model.schedule.excursion.finish | formatDate }}
                <div class="text-caption orange--text text--darken-2 font-weight-bold mt-1">Complete</div>
                {{ model.schedule.complete.start | formatDate }} - {{ model.schedule.complete.finish | formatDate }}
              </template>
            </div>
          </div>
        </v-tab-item>
        <!-- ---------------------- Itinerary ---------------------- -->
        <v-tab-item>
          <div class="d-flex flex-row">
            <div class="flex-1 ma-4">
              <div class="overline">Schedule</div>
              <v-expansion-panels v-model="dayIndex" v-if="model.itinerary.length">
                <v-expansion-panel v-for="(day, index) in model.itinerary" :key="'day' + index">
                  <v-expansion-panel-header>
                    <v-icon v-if="day.error" color="red">mdi-alert</v-icon>
                    <div class="d-flex flex-column">
                      <div>Day {{ index + 1 }}: {{ day.date | formatDate }}</div>
                      <div v-if="day.name" class="font-weight-bold font-size-16 mt-2">{{ day.name }}</div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-alert v-else type="warning">
                You must specify a duration for the tour before you can add itinerary items.
              </v-alert>
            </div>
            <v-divider vertical></v-divider>
            <div class="flex-1 ma-4">
              <div v-if="dayIndex != null" class="text-h5 mb-2 d-flex flex-row align-center">
                Day {{ model.itinerary[dayIndex].day }}
                <v-spacer></v-spacer>
                <v-btn v-if="model.itinerary[dayIndex].editing == true" @click="editDay(dayIndex, false)" color="green" dark><v-icon left>mdi-check</v-icon> Update Record</v-btn>
                <v-btn v-else icon @click="editDay(dayIndex, true)"><v-icon>mdi-pencil</v-icon></v-btn>
              </div>
              <v-sheet v-if="dayIndex != null && !model.itinerary[dayIndex].editing" rounded color="grey lighten-4" class="pa-3">
                <div class="overline">Details</div>
                <ul>
                  <li>Name: {{ model.itinerary[dayIndex].name }}</li>
                  <li>Description: ...</li>
                  <li>Paved Distance: {{ model.itinerary[dayIndex].distance.paved }} km</li>
                  <li>Gravel Distance: {{ model.itinerary[dayIndex].distance.gravel }} km</li>
                  <li>Optional: {{ model.itinerary[dayIndex].optional }}</li>
                </ul>
                <div class="overline">Hotel</div>
                <div v-if="model.itinerary[dayIndex].hotel.id">
                  <div class="font-weight-bold mb-2">{{ model.itinerary[dayIndex].hotel.name }}</div>
                  <div v-if="model.itinerary[dayIndex].hotel.season != -1">
                    <v-expansion-panels v-if="model.itinerary[dayIndex].hotel.rooms.length">
                      <v-expansion-panel v-for="(room, index) in model.itinerary[dayIndex].hotel.rooms" :key="model.itinerary[dayIndex].hotel.id + '-room-' + index">
                        <v-expansion-panel-header>{{ room.description }} - {{ room.type }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div v-if="model.itinerary[dayIndex].hotel.seasons.length > 1" class="font-italic mb-3">
                            Seasonal Rates: {{ model.itinerary[dayIndex].hotel.seasons[model.itinerary[dayIndex].hotel.season].name }}
                          </div>
                          Rack: <strong>{{ room.rates[model.itinerary[dayIndex].hotel.season].rack }} {{ model.itinerary[dayIndex].hotel.currency.code }}</strong><br>
                          STO: <strong>{{ room.rates[model.itinerary[dayIndex].hotel.season].sto }} {{ model.itinerary[dayIndex].hotel.currency.code }}</strong><br>
                          Guide: <strong>{{ room.rates[model.itinerary[dayIndex].hotel.season].guide }} {{ model.itinerary[dayIndex].hotel.currency.code }}</strong>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-alert v-else type="warning" class="font-italic">No rooms configured.</v-alert>
                  </div>
                  <v-alert v-else type="error">
                    There are no valid seasonal rates
                  </v-alert>
                  <v-alert v-if="model.itinerary[dayIndex].hotel.notes" type="info" class="font-italic mt-2" outlined>
                    Notes: {{ model.itinerary[dayIndex].hotel.notes }}
                  </v-alert>
                </div>
                <v-alert v-else type="info" class="font-italic">
                  No hotel assigned.
                </v-alert>

                <!-- ---------------------- Restaurants ---------------------- -->
                <div class="overline mt-3">Restaurants</div>
                <div v-if="model.itinerary[dayIndex].restaurants.length">
                  <v-expansion-panels>
                    <v-expansion-panel v-for="(restaurant, index) in model.itinerary[dayIndex].restaurants" :key="model.itinerary[dayIndex].hotel.id + '-restaurant-' + index">
                      <v-expansion-panel-header>{{ restaurant.name }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <pre>
                          {{ restaurant.dietaryOfferings }}
                          {{ restaurant.meals }}
                        </pre>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
                <v-alert v-else type="info" class="font-italic">
                  No restaurants assigned.
                </v-alert>

                <!-- ---------------------- Activities ---------------------- -->
                <div class="overline mt-3">Activities</div>
                <div v-if="model.itinerary[dayIndex].activities.length">
                  <v-expansion-panels>
                    <v-expansion-panel v-for="(activity, index) in model.itinerary[dayIndex].activities" :key="model.itinerary[dayIndex].hotel.id + '-activity-' + index">
                      <v-expansion-panel-header>{{ activity.name }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        {{ activity.type }}
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
                <v-alert v-else type="info" class="font-italic">
                  No activities assigned.
                </v-alert>
                <!-- ------------ Edit Record ------------ -->
                <div class="d-flex">
                  <v-spacer></v-spacer>
                  <v-btn @click="editDay(dayIndex, true)"><v-icon left>mdi-pencil</v-icon>Edit Record</v-btn>
                </div>
              </v-sheet>
              <v-sheet v-if="dayIndex != null && model.itinerary[dayIndex].editing" rounded color="grey lighten-4" class="pa-3">
                <div class="overline">Details</div>
                <v-text-field label="Name" v-model="model.itinerary[dayIndex].name"></v-text-field>
                <v-text-field label="Description" v-model="model.itinerary[dayIndex].description"></v-text-field>
                <v-text-field label="Image" v-model="model.itinerary[dayIndex].image"></v-text-field>
                <v-text-field label="Paved Distance" v-model="model.itinerary[dayIndex].distance.paved" suffix="km"></v-text-field>
                <v-text-field label="Gravel Distance" v-model="model.itinerary[dayIndex].distance.gravel" suffix="km"></v-text-field>
                <v-checkbox v-model="model.itinerary[dayIndex].optional" label="Optional Riding Day"></v-checkbox>

                <div class="overline">Hotel</div>
                <v-autocomplete
                  v-model="hotel"
                  :items="hotels"
                  item-text="name"
                  return-object
                  @change="hotelChange()"
                  label="Hotel"></v-autocomplete>

                <div class="overline">Restaurants</div>
                <v-autocomplete
                  v-model="model.itinerary[dayIndex].restaurants"
                  :items="restaurants"
                  item-text="name"
                  return-object
                  label="Restaurants"
                  multiple></v-autocomplete>

                <div class="overline">Activities</div>
                <v-autocomplete
                  v-model="model.itinerary[dayIndex].activities"
                  :items="activities"
                  item-text="name"
                  return-object
                  label="Activities"
                  multiple></v-autocomplete>
                  <!-- ------------ Edit Record ------------ -->
                  <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn @click="editDay(dayIndex, false)" color="green" dark><v-icon left>mdi-check</v-icon> Update Record</v-btn>
                  </div>
              </v-sheet>
            </div>
          </div>
        </v-tab-item>
        <!-- ---------------------- Bookings ---------------------- -->
        <v-tab-item v-if="model.id">
          <v-alert type="info" class="mt-6">TODO: Display bookings</v-alert>
        </v-tab-item>
        <!-- ---------------------- Crew ---------------------- -->
        <v-tab-item>
          <v-container>
            <div class="d-flex flex-row flex-wrap">
              <v-card width="200" height="318" class="ma-4">
                <v-sheet color="orange darken-3" class="badge-top d-flex flex-column align-center">
                  <div class="badge-header pt-2 d-flex justify-end mt-3"></div>
                </v-sheet>
                <v-sheet color="white" class="d-flex flex-column align-center">
                  <v-avatar class="badge-photo" size="80">
                    <v-img v-if="model.crew.lead.photo" :src="model.crew.lead.photo" contain></v-img>
                    <v-img v-else src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Femployee-placeholder.jpeg?alt=media&token=3ba9a1a3-f661-42d2-bc16-875bbcb9646c" contain></v-img>
                  </v-avatar>
                  <div class="text-h6 mt-2 grey--text text--darken-2">Lead Guide</div>
                  <div class="mt-2 mx-4 justify-center">
                    <v-select v-if="!model.crew.lead.name || model.crew.lead.editing" v-model="model.crew.lead" label="Select Employee" :items="filteredEmployees" item-text="name" return-object @input="model.crew.lead.editing = false"></v-select>
                    <div v-else class="d-flex flex-row"><div class="flex-1">{{ model.crew.lead.name }}</div> <v-btn icon small @click="model.crew.lead.editing = true; $forceUpdate()"><v-icon small>mdi-pencil</v-icon></v-btn></div>
                    <v-select v-if="model.crew.lead.name && !model.crew.lead.editing" v-model="model.crew.lead.vehicle" label="Select Vehicle" :items="vehicles" item-text="model" return-object></v-select>
                  </div>
                </v-sheet>
              </v-card>

              <template v-if="model.crew.support.length">
                <v-card v-for="(employee, index) in model.crew.support" :key="'support-' + index" width="200" height="318" class="ma-4">
                  <v-sheet color="green darken-3" class="badge-top d-flex flex-column align-center">
                    <div class="badge-header pt-2 d-flex justify-end">
                      <v-btn dark icon @click="removeSupportDriver(index)"><v-icon>mdi-close</v-icon></v-btn>
                    </div>
                  </v-sheet>
                  <v-sheet color="white" class="d-flex flex-column align-center">
                    <v-avatar class="badge-photo" size="80">
                      <v-img v-if="employee.photo" :src="employee.photo" contain></v-img>
                      <v-img v-else src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Femployee-placeholder.jpeg?alt=media&token=3ba9a1a3-f661-42d2-bc16-875bbcb9646c" contain></v-img>
                    </v-avatar>
                    <div class="text-h6 mt-2 grey--text text--darken-2">Support Guide</div>
                    <div class="mt-2 mx-4 justify-center">
                      <v-select v-if="!employee.name || employee.editing" v-model="model.crew.support[index]" label="Select Employee" :items="filteredEmployees" item-text="name" return-object @input="employee.editing = false"></v-select>
                      <div v-else class="d-flex flex-row"><div class="flex-1">{{ employee.name }}</div> <v-btn icon small @click="employee.editing = true; $forceUpdate()"><v-icon small>mdi-pencil</v-icon></v-btn></div>
                      <v-select v-if="employee.name && !employee.editing" v-model="employee.vehicle" label="Select Vehicle" :items="vehicles" item-text="model" return-object></v-select>
                    </div>
                  </v-sheet>
                </v-card>
              </template>

              <v-card @click="addSupportDriver()" flat width="200" height="318" class="ma-4 add-support-driver">
                <v-icon large>mdi-plus-circle-outline</v-icon>
                <div class="text-caption grey--text text--darken-2">Add Support Driver</div>
              </v-card>
            </div>
          </v-container>
        </v-tab-item>
        <!-- ---------------------- Vehicles ---------------------- -->
        <v-tab-item>
          <v-container>
            <v-list subheader two-line class="vehicle-list">
              <v-list-group v-for="provider in computedVehicleProviders" :key="provider" :value="true">
                <template v-slot:activator>
                  <v-list-item-avatar>
                    <v-img contain :src="getVehicleProvider(provider).logo" height="40" width="40"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-title>{{ getVehicleProvider(provider).name }}</v-list-item-title>
                </template>
                <draggable
                  v-model="vehiclesByProvider[provider]"
                  :group="{ name: 'vehicles' }"
                  @end="onVehicleDragEnd(provider)"
                >
                  <v-list-item v-for="vehicle in vehiclesByProvider[provider]" :key="vehicle.id" class="vehicle-item">
                    <v-icon>mdi-drag-vertical</v-icon>
                    <!-- {{ vehicle.order }} -->
                    <v-list-item-avatar class="mx-4">
                      <v-img contain :src="vehicle.images.thumbnail || ''" height="40" width="40"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ vehicle.make }} {{ vehicle.model }}</v-list-item-title>
                      <v-list-item-subtitle v-if="vehicle.options.length">{{ vehicle.options.length }} options</v-list-item-subtitle>
                      <v-list-item-subtitle v-else>No options defined</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="d-flex flex-row">
                      <v-btn icon @click="confirmDeleteVehicle(vehicle)">
                        <v-icon class="vehicle-icon">mdi-delete</v-icon>
                      </v-btn>
                      <v-btn icon @click="openEditVehicleDialog(vehicle)">
                        <v-icon class="vehicle-icon">mdi-pencil</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </draggable>
              </v-list-group>
            </v-list>
          </v-container>
        </v-tab-item>
        <!-- ---------------------- Actions ---------------------- -->
        <v-tab-item>
          <v-alert type="info" class="mt-6">Todo: Manage actions</v-alert>
        </v-tab-item>
        <!-- ---------------------- Checklists ---------------------- -->
        <v-tab-item>
          <v-alert type="info" class="mt-6">Todo: Manage checklists</v-alert>
        </v-tab-item>
        <!-- ---------------------- Logs ---------------------- -->
        <v-tab-item v-if="model.id">
          <v-alert type="info" class="mt-6">Todo: Change logs</v-alert>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions class="mt-0">
      <v-btn small text @click="go('AdminTours')"><v-icon left>mdi-cancel</v-icon>Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="model.id" color="primary" @click="update()" :loading="saving">
        <v-icon left>mdi-content-save-outline</v-icon>Save Changes
      </v-btn>
      <v-btn v-else color="primary" @click="create()" :loading="saving" :disabled="model.schedule.complete.range.length != 2">
        <v-icon left>mdi-content-save-outline</v-icon>Create Tour
      </v-btn>
    </v-card-actions>
    <!-- ------------ Change Template Dialog ------------ -->
    <v-dialog v-model="templateDialog" width="400">
      <v-card>
        <v-card-title class="headline">Change Dialog</v-card-title>
        <v-card-text>
          <v-alert type="error">
            This action is permanent and cannot be undone. 
          </v-alert>
          <v-checkbox v-model="templateChangeConfirmed" color="red" label="I understand the consequences"></v-checkbox>
        </v-card-text>
        <v-card-actions v-if="!loading && model">
          <v-btn text @click="templateDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="templateChangeConfirmed == false" color="red" text @click="changeTemplate(true)">Confirm Change</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------ Reload Template Dialog ------------ -->
    <v-dialog v-model="reloadDialog" width="400">
      <v-card>
        <v-card-title class="headline">Reload Dialog</v-card-title>
        <v-card-text>
          <v-alert type="error">
            This action will replace the current tour template snapshot with the latest version.
            This action is permanent and cannot be undone. 
            It is not recommended to reload if the tour already has bookings. 
          </v-alert>
          <v-checkbox v-model="templateReloadConfirmed" color="red" label="I understand the consequences"></v-checkbox>
        </v-card-text>
        <v-card-actions v-if="!loading && model">
          <v-btn text @click="reloadDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="!templateReloadConfirmed" color="red" text @click="reloadTemplate(true)">Confirm Change</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------ Modify Vehicle Option Dialog ------------ -->
    <v-dialog v-model="modifyVehicleOptionDialog" width="400">
      <v-card>
        <v-card-title>
          Vehicle Option
          <v-spacer></v-spacer>
          <v-btn icon @click="modifyVehicleOptionDialog=false"><v-icon>mdi-close</v-icon></v-btn>  
        </v-card-title>
        <v-card-text>
          <v-text-field label="Name" v-model="modifyVehicleOptionModel.name"></v-text-field>
          <v-text-field label="Price" v-model="modifyVehicleOptionModel.price" prefix="C$"></v-text-field>
          <v-text-field label="Cost" v-model="modifyVehicleOptionModel.cost" prefix="C$" suffix="per diem"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="modifyVehicleOptionDialog=false" color="primary" text>Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete Vehicle Dialog -->
    <v-dialog v-model="deleteVehicleDialog" width="400">
      <v-card>
        <v-card-title class="headline">Delete Vehicle</v-card-title>
        <v-card-text>
          <v-alert type="error">
            This action is permanent and cannot be undone. 
          </v-alert>
          <v-checkbox v-model="vehicleDeletionConfirmed" color="red" label="I understand the consequences"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="deleteVehicleDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text :disabled="!vehicleDeletionConfirmed" @click="deleteVehicle">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Vehicle Dialog -->
    <v-dialog v-if="currentVehicle" v-model="vehicleDialog" width="800">
      <v-card>
        <v-card-title>
          {{ currentVehicle.id ? 'Edit Vehicle' : 'New Vehicle' }}
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-row">
            <div class="flex-1">
              <v-text-field label="Make" v-model="currentVehicle.make"></v-text-field>
              <v-text-field label="Model" v-model="currentVehicle.model"></v-text-field>
              <v-textarea label="Description" v-model="currentVehicle.description" rows="2"></v-textarea>
              <v-text-field 
                label="Thumbnail URL" 
                v-model="currentVehicle.images.thumbnail" 
                prepend-icon="mdi-attachment" 
                @click:prepend="triggerFileUpload('thumbnail')"
              >
                <template v-slot:progress>
                  <v-progress-linear
                    v-if="isImageUploading"
                    :value="uploadProgress"
                    color="primary"
                    height="7"
                  ></v-progress-linear>
                </template>
              </v-text-field>
              <input type="file" ref="thumbnailInput" style="display: none" @change="uploadImage('thumbnail', $event)">
              <v-text-field label="Rental Cost" v-model="currentVehicle.rate.cost" :prefix="currentVehicle.rate.currency + '$'" suffix="daily"></v-text-field>
              <v-text-field label="Supplement Charge" v-model="currentVehicle.rate.supplement" :prefix="currentVehicle.rate.currency + '$'" suffix="per tour"></v-text-field>
              <v-text-field label="Fuel Capacity" v-model="currentVehicle.fuel.capacity" suffix="L"></v-text-field>
              <v-text-field label="Fuel Range" v-model="currentVehicle.fuel.range" suffix="km"></v-text-field>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div class="flex-1">
              <div class="overline">Images</div>
              <div class="d-flex images">
                <v-img v-if="currentVehicle.images.thumbnail" :src="currentVehicle.images.thumbnail" contain height="100" width="100"></v-img>
              </div>
              <div class="d-flex flex-row mb-2 align-center">
                <div class="text-overline flex-1">Options</div>
                <v-btn text @click="openOptionDialog"><v-icon left>mdi-plus</v-icon>Add Option</v-btn>
              </div>
              <div v-for="(option, index) in currentVehicle.options" :key="'option-list-' + index">
                <div class="d-flex flex-row align-center">
                  <div class="subtitle flex-3">{{ index + 1 }}. {{ option.name }}</div>
                  <div class="subtitle flex-1 green--text">{{ formatCurrency(option.price) }}</div>
                  <v-btn small icon @click="removeOption(index)"><v-icon small>mdi-delete</v-icon></v-btn>
                  <v-btn small icon @click="openOptionDialog(index)"><v-icon small>mdi-pencil</v-icon></v-btn>
                </div>
              </div>
              <div v-if="!currentVehicle.options || currentVehicle.options.length === 0" class="grey--text">No options provided.</div>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="secondary" text @click="vehicleDialog = false" :disabled="isVehicleSaving">
            <v-icon left>mdi-close</v-icon>Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="currentVehicle.id" color="primary" dark @click="saveModifiedVehicle" :loading="isVehicleSaving">
            <v-icon left>mdi-content-save-outline</v-icon>Update Vehicle
          </v-btn>
          <v-btn v-else color="primary" dark @click="saveVehicle" :loading="isVehicleSaving">
            <v-icon left>mdi-content-save-outline</v-icon>Save Vehicle
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Option Dialog -->
    <v-dialog v-model="optionDialog" width="400">
      <v-card>
        <v-card-title>
          Vehicle Option
          <v-spacer></v-spacer>
          <v-btn icon @click="optionDialog=false"><v-icon>mdi-close</v-icon></v-btn>  
        </v-card-title>
        <v-card-text>
          <v-text-field label="Name" v-model="option.name"></v-text-field>
          <v-text-field label="Price" v-model="option.price" prefix="C$"></v-text-field>
          <v-text-field label="Cost" v-model="option.cost" prefix="C$" suffix="per diem"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="option.index >= 0" @click="saveOption()" color="primary" text>Save Option</v-btn>
          <v-btn v-else @click="addOption()" color="primary" text>Add Option</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { getFirestore, doc, getDocs, addDoc, setDoc, onSnapshot, collection } from 'firebase/firestore'
import { DateTime } from "luxon"
import { isEqual, cloneDeep } from 'lodash-es'
import Draggable from "vuedraggable"
export default {
  name: 'AdminToursManage',
  components: {
    Draggable,
  },
  data: () => ({
    loading: true,
    saving: false,
    success: '',
    error: '',
    tab: 0,
    isPageLoading: false,
    isVehicleSaving: false,
    isImageUploading: false,
    uploadProgress: 0,
    dayIndex: null,
    editDetails: false,
    templateDialog: false,
    templateChangeConfirmed: false,
    reloadDialog: false,
    templateReloadConfirmed: false,
    hotel: [],
    modifyVehicleDialog: false,
    modifyVehicleModel: {},
    modifyVehicleOptionDialog: false,
    modifyVehicleOptionModel: {},
    vehicleDialog: false,
    deleteVehicleDialog: false,
    optionDialog: false,
    currentVehicle: null,
    vehicleDeletionConfirmed: false,
    model: {},
    defaultModel: {
      id: null,
      name: '',
      tourCode: '',
      actions: {},
      checklists: {},
      bookings: [],
      crew: {
        lead: {
          id: null,
          name: '',
          email: '',
          photo: '',
          vehicle: '',
          actions: [],
          checklists: [],
          editing: true,
        },
        support: [],
      },
      details: {},
      itinerary: [],
      logs: [],
      status: 'draft',
      schedule: {
        excursion: {
          start: '',
          finish: '',
          range: [],
        },
        primary: {
          start: '',
          finish: '',
          range: [],
        },
        complete: {
          start: '',
          finish: '',
          range: [],
        }
      },
      vehicles: [],
      template: null,
    },
    option: {
      name: '',
      price: 0,
      cost: 0,
    },
    activities: [],
    employees: [],
    hotels: [],
    restaurants: [],
    vehicles: [],
    templateVehicles: [],
    templates: [],
    vehicleProviders: [],
    selectedTemplate: null,
    isTemplateCurrent: false,
    tourOverrides: false,
    // ------------ Item Lists ------------
    loadedTables: [],

    continents: [
      'Africa',
      'Antarctica',
      'Asia',
      'Oceania',
      'Europe',
      'North America',
      'South America',
    ],
    countries: [],
    status_types: [
      'draft',
      'open',
      'private',
      'cancelled',
      'completed',
    ],
    //
    vehiclesByProvider: {},
  }),
  created() {
    this.model.id = this.$route.params.id
    if (this.model.id == 'new') {
      this.model = cloneDeep(this.defaultModel)
      this.loaded('template')
      console.log('New Tour')
    } else {
      console.log('Existing Tour')
      const db = getFirestore()
      const docRef = doc(db, 'tours', this.model.id)
      onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          let tour = doc.data()
          tour.id = doc.id
          this.model = tour
          this.loaded('tour')
        }
      })
    }
    this.load('employees')
    this.load('templates')
    this.load('vehicles')
    this.load('hotels')
    this.load('restaurants')
    this.load('activities')
    this.load('vehicleProviders')
  },
  methods: {
    load(table) {
      const db = getFirestore()
      const collectionRef = collection(db, table)
      getDocs(collectionRef).then((querySnapshot) => {
        let items = []
        querySnapshot.forEach((doc) => {
          let item = doc.data()
          item.id = doc.id
          items.push(item)
        })
        this.$set(this, table, items)
        this.loaded(table)
      })
    },
    loaded(table) {
      console.log('Loaded', table)
      this.loadedTables.push(table)
      if (this.loading && ['employees', 'vehicles', 'hotels', 'restaurants', 'activities', 'templates', 'vehicleProviders'].every(t => this.loadedTables.includes(t))) {
        this.initializeVehiclesByProvider();
        if (this.loadedTables.includes('template')) {
          console.log('Loaded all tables NEW')
          // There is no template selected, so we can't load the vehicles yet
          this.loading = false
        }
        if (this.loadedTables.includes('tour')) {
          console.log('Loaded all tables EXISTING')
          this.setSelectedTemplate()
          this.loading = false
        }
      }
    },
    create() {
      this.saving = true
      this.error = ''
      this.success = ''
      const db = getFirestore()
      const collectionRef = collection(db, 'tours')
      addDoc(collectionRef, this.model).then((docRef) => {
        this.success = "Tour added successfully."
        this.saving = false
        this.model.id = docRef.id
      }).catch((error) => {
        this.error = "Error adding tour. Please try again."
        this.saving = false
      })
    },
    update() {
      this.saving = true
      this.error = ''
      this.success = ''
      const db = getFirestore()
      const collectionRef = collection(db, 'tours')
      const docRef = doc(collectionRef, this.model.id)
      setDoc(docRef, this.model).then(() => {
        this.success = "Updated tour successfully."
        this.saving = false
      }).catch((error) => {
        this.error = "Error updating tour. Please try again."
        this.saving = false
      })
      this.setSelectedTemplate()
    },
    changeTemplate(confirmed) {
      this.templateChangeConfirmed = false
      this.templateReloadConfirmed = false
      console.log('confirmed', confirmed)
      if (confirmed == false || (this.model.tour && confirmed  == undefined)) {
        this.templateDialog = true
        return
      }
      //
      console.log('Change Template', this.selectedTemplate)
      if (isEqual(this.model.template, this.selectedTemplate)) return
      this.model.template = cloneDeep(this.selectedTemplate)
      this.model.details = cloneDeep(this.selectedTemplate.details)
      this.model.itinerary = cloneDeep(this.selectedTemplate.itinerary)
      this.model.excursion = cloneDeep(this.selectedTemplate.excursion)
      this.model.location = cloneDeep(this.selectedTemplate.location)
      if (this.model.schedule.primary.start) this.scheduleChange()
      // For a new tour, we have to populate the vehicles from the template vehicleProviders
      this.templateVehicles = []
      this.model.template.vehicleProviders.forEach(provider => {
        this.vehicles.forEach(vehicle => {
          if (vehicle.providerId === provider) this.templateVehicles.push(vehicle)
        })
      })
      if (this.model.id) {
        // Check if the vehicles are the same as the template vehicles
        console.log('isEqual', isEqual(this.model.vehicles, this.templateVehicles))
        console.log('template vehicles', this.templateVehicles)
        console.log('tour vehicles', this.model.vehicles)
        console.log('We need to change the vehicles!')
        // Change the vehicles
        this.model.vehicles = cloneDeep(this.templateVehicles)
      } else {
        // The template is new so we can set the vehicles
        this.model.vehicles = cloneDeep(this.templateVehicles)
      }
      this.setSelectedTemplate()
      this.templateDialog = false
    },
    reloadTemplate() {
      // Find the new template and load it
      this.templateReloadConfirmed = false
      this.setSelectedTemplate(true)
      this.changeTemplate(true)
      this.reloadDialog = false
    },
    scheduleChange() {
      this.model.schedule.primary.start = this.model.schedule.primary.range[0]
      this.model.schedule.primary.finish = DateTime.fromISO(this.model.schedule.primary.start).plus({ days: this.model.details.duration-1 }).toFormat('yyyy-MM-dd')
      this.model.schedule.primary.range = [this.model.schedule.primary.start, this.model.schedule.primary.finish]
      this.model.schedule.complete.start = this.model.schedule.primary.start
      this.model.schedule.complete.finish = this.model.schedule.primary.finish
      if (this.model.excursion.available) {
        this.model.schedule.excursion.start = DateTime.fromISO(this.model.schedule.primary.start).minus({ days: this.model.excursion.duration - 1 }).toFormat('yyyy-MM-dd')
        this.model.schedule.excursion.finish = DateTime.fromISO(this.model.schedule.excursion.start).plus({ days: this.model.excursion.duration - 1 }).toFormat('yyyy-MM-dd')
        this.model.schedule.excursion.range = [this.model.schedule.excursion.start, this.model.schedule.excursion.finish]
        this.model.schedule.complete.start = this.model.schedule.excursion.start
      } else {
        this.model.schedule.excursion = cloneDeep(this.defaultModel.schedule.excursion)
      }
      this.model.schedule.complete.range = [this.model.schedule.complete.start, this.model.schedule.complete.finish]
      this.model.name = this.model.template.details.name + ': ' + this.$options.filters.formatDate(this.model.schedule.primary.start) + ' - ' + this.$options.filters.formatDate(this.model.schedule.primary.finish)
      console.log('3')
      this.model.itinerary.forEach((day, index) => {
        day.date = DateTime.fromISO(this.model.schedule.primary.start).plus({ days: index }).toFormat('yyyy-MM-dd')
        day.error = false
        day.editing = false
        if (day.hotel.id != undefined) {
          day.hotel = this.fetchElementById(this.hotels, day.hotel.id)
          day.hotel.season = this.getSeason(day.hotel.seasons, day.date)
          if (day.hotel.season == -1) day.error = true
        }
      })
      if (this.model.excursion.available) {
        console.log('4')
        this.model.excursion.itinerary.forEach((day, index) => {
          day.date = DateTime.fromISO(this.model.schedule.excursion.start).plus({ days: index }).toFormat('yyyy-MM-dd')
        })
      }
      // ------------ Determine the tourCode ------------
      if (this.model.tourCode == '') {
        let tourCode = this.model.schedule.primary.start.substr(2, 2) + '-' + this.model.template.code + '-'
        console.log('tourCode', tourCode)
        // We now need to find a unique incremental two digit (01-99) number
        // Find the largest tourCode that matches the pattern (if any)
        const db = getFirestore()
        const collectionRef = collection(db, 'tours')
        getDocs(collectionRef).then((querySnapshot) => {
          let tourCodes = []
          querySnapshot.forEach((doc) => {
            let tour = doc.data()
            // We only want to check the tourCodes that match the pattern
            if (tour.tourCode.startsWith(tourCode)) {
              tourCodes.push(tour.tourCode)
            }
          })
          // We are going to base the number based on the length of the array
          let nextNumber = tourCodes.length + 1
          // Now we can set the tourCode
          this.model.tourCode = tourCode + (nextNumber < 10 ? '0' : '') + nextNumber
        })
      }
    },
    filterVehiclesByContinent(continent) {
      if (continent) {
        this.vehicles = this.vehicles.filter(vehicle => vehicle.provider && vehicle.provider.continent === continent)
      }
    },
    hotelChange() {
      let hotel = this.fetchElementById(this.hotels, this.hotel.id)
      hotel.season = this.getSeason(hotel.seasons, this.model.itinerary[this.dayIndex].date)
      if (hotel.season == -1) this.model.itinerary[this.dayIndex].error = true
      this.model.itinerary[this.dayIndex].hotel = hotel
    },
    fetchElementById(array, id) {
      return array.find(element => element.id == id)
    },
    calendarEvents(date) {
      if (!this.model.excursion.available) return false
      const parsedDateToCheck = DateTime.fromFormat(date, "yyyy-MM-dd")
      const parsedRangeStart = DateTime.fromFormat(this.model.schedule.excursion.start, "yyyy-MM-dd")
      const parsedRangeFinish = DateTime.fromFormat(this.model.schedule.excursion.finish, "yyyy-MM-dd")
      return parsedDateToCheck >= parsedRangeStart && parsedDateToCheck <= parsedRangeFinish
    },
    getSeason(seasons, date) {
      let seasonIndex = -1
      if (seasons.length == 1 && seasons[0].start == '' && seasons[0].finish == '') return 0
      seasons.forEach((season, index) => {
        const parsedDateToCheck = DateTime.fromFormat(date, "yyyy-MM-dd")
        const parsedRangeStart = DateTime.fromFormat(season.start, "yyyy-MM-dd")
        const parsedRangeFinish = DateTime.fromFormat(season.finish, "yyyy-MM-dd")
        if (parsedDateToCheck >= parsedRangeStart && parsedDateToCheck <= parsedRangeFinish) {
          seasonIndex = index
        }
      })
      return seasonIndex
    },
    editDay(index, status) {
      this.model.itinerary[index].editing = status
      this.$forceUpdate()
    },
    addSupportDriver() {
      this.model.crew.support.push({
        id: null,
        name: '',
        email: '',
        photo: '',
        vehicle: '',
        actions: [],
        checklists: [],
        editing: true,
      })
    },
    removeSupportDriver(index) {
      this.model.crew.support.splice(index, 1)
    },
    modifyVehicle(provider, vehicle) {
      this.modifyVehicleModel = vehicle
      this.modifyVehicleProvider = provider
      this.modifyVehicleDialog = true
    },
    saveModifiedVehicle() {
      this.model.vehicles.forEach((vehicle, index) => {
        if (vehicle.id == this.modifyVehicleModel.id) {
          this.model.vehicles[index] = cloneDeep(this.modifyVehicleModel)
        }
      })
      this.vehicleDialog = false
    },
    modifyVehicleOption(option) {
      if (option == undefined) {
        this.modifyVehicleModel.options.push({
          name: '',
          price: 0,
          cost: 0,
        })
        option = this.modifyVehicleModel.options[this.modifyVehicleModel.options.length - 1]
      }
      this.modifyVehicleOptionModel = option
      this.modifyVehicleOptionDialog = true
    },
    removeVehicleOption(index) {
      this.modifyVehicleModel.options.splice(index, 1)
    },
    setSelectedTemplate(reload) {
      this.isTemplateCurrent = false
      console.log('6')
      this.templates.forEach(template => {
        if (template.id == this.model.template.id) {
          if (isEqual(template, this.model.template) || reload == true) {
            this.selectedTemplate = cloneDeep(template)
            this.isTemplateCurrent = true
          }
        }
      })
      // Check if there have been any data changes to the template
      if (this.selectedTemplate) {
        this.tourOverrides = []
        // console.log('compare', isEqual(this.model.details, this.selectedTemplate.details), this.model.details, this.selectedTemplate.details)
        console.log('7', this.model, this.selectedTemplate)
        if (!isEqual(this.model.details, this.selectedTemplate.details)) this.tourOverrides.push('details')
        // if (!isEqual(this.model.itinerary, this.selectedTemplate.itinerary)) this.tourOverrides.push('itinerary')
        // if (!isEqual(this.model.excursion, this.selectedTemplate.excursion)) this.tourOverrides.push('excursion')
        // if (!isEqual(this.model.location, this.selectedTemplate.location)) this.tourOverrides.push('location')
        // if (!isEqual(this.model.crew, this.selectedTemplate.crew)) this.tourOverrides.push('crew')
        // if (!isEqual(this.model.vehicles, this.templateVehicles)) this.tourOverrides.push('vehicles')
      }
    },
    getVehicleProvider(providerId) {
      return this.vehicleProviders.find(provider => provider.id == providerId)
    },
    openCreateVehicleDialog(providerId) {
      this.currentVehicle = { ...this.defaultVehicle, providerId };
      this.vehicleDialog = true;
    },
    // ----------------------------------------------------------------
    confirmDeleteVehicle(vehicle) {
      this.currentVehicle = { ...vehicle };
      this.deleteVehicleDialog = true;
    },
    openEditVehicleDialog(vehicle) {
      this.currentVehicle = { ...vehicle };
      this.vehicleDialog = true;
    },
    // ----------------------------------------------------------------
    deleteVehicle() {
      console.log('delete', this.currentVehicle, this.model.vehicles)
      // remove the current vehicle from the model
      let filteredVehicles = this.model.vehicles.filter(vehicle => vehicle.id !== this.currentVehicle.id);
      this.model.vehicles = filteredVehicles
      console.log('delete after', this.model.vehicles)
      this.deleteVehicleDialog = false;
      this.$forceUpdate()
    },
    uploadImage(type, event) {
      const file = event.target.files[0];
      const storage = getStorage();
      const storageRef = ref(storage, 'images/' + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed', snapshot => {
        this.isImageUploading = true;
        this.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      }, error => {
        this.pageErrorMessage = 'Failed to upload image: ' + error.message;
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          if (type === 'thumbnail') {
            this.currentVehicle.images.thumbnail = downloadURL;
          } else if (type === 'logo') {
            this.currentVehicleProvider.logo = downloadURL;
          }
          this.isImageUploading = false;
        });
      });
    },
    triggerFileUpload(type) {
      if (type === 'thumbnail') {
        this.$refs.thumbnailInput.click();
      } else if (type === 'logo') {
        this.$refs.logoInput.click();
      }
    },
    uploadProviderImage(type, event) {
      this.uploadImage(type, event);
    },
    openOptionDialog(index = null) {
      if (index !== null) {
        this.option = { ...this.currentVehicle.options[index] };
        this.option.index = index
        this.optionDialog = true;
      } else {
        this.option = { name: '', price: 0, cost: 0 };
        this.optionDialog = true;
      }
    },
    removeOption(index) {
      this.currentVehicle.options.splice(index, 1);
    },
    addOption() {
      this.currentVehicle.options.push({ ...this.option });
      this.optionDialog = false;
    },
    saveOption() {
      this.currentVehicle.options[this.option.index] = this.option
      this.optionDialog = false;
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD'
      }).format(value);
    },
    onVehicleDragEnd(providerId) {
      const vehicles = this.vehiclesByProvider[providerId];
      vehicles.forEach((vehicle, index) => {
        vehicle.order = index;
      });
      this.updateModelVehicles();
    },
    updateModelVehicles() {
      this.model.vehicles = Object.values(this.vehiclesByProvider).flat();
    },
    initializeVehiclesByProvider() {
      this.vehiclesByProvider = this.model.vehicles.reduce((acc, vehicle) => {
        if (!acc[vehicle.providerId]) {
          acc[vehicle.providerId] = [];
        }
        acc[vehicle.providerId].push(vehicle);
        return acc;
      }, {});

      // Optionally sort the vehicles within each provider
      for (const providerId in this.vehiclesByProvider) {
        this.vehiclesByProvider[providerId].sort((a, b) => a.order - b.order);
      }
    },
  },
  computed: {
    // vehiclesByProvider(provider) {
    //   return this.model.vehicles.reduce((acc, vehicle) => {
    //     if (!acc[vehicle.providerId]) {
    //       acc[vehicle.providerId] = [];
    //     }
    //     acc[vehicle.providerId].push(vehicle);
    //     return acc;
    //   }, {});
    // },
    providerOptions() {
      return this.vehicleProviders.map(provider => ({
        id: provider.id,
        name: provider.name || 'Unnamed Provider'
      }));
    },
    filteredEmployees() {
      return this.employees.filter(employee => {
        let assigned = false
        this.model.crew.support.forEach(crew => {
          if (crew.id == employee.id) assigned = true
        })
        if (this.model.crew.lead.id == employee.id) assigned = true
        return !assigned
      })
    },
    computedVehicleProviders() {
      return Object.keys(this.vehiclesByProvider);
    },
  },
  watch: {
    'model.vehicles': {
      handler() {
        this.initializeVehiclesByProvider();
      },
      deep: true,
    },
  },
  filters: {
    formatDate(value) {
      return DateTime.fromISO(value).toFormat('MMMM d, yyyy')
    },
    formatCurrency(value) {
      if (!value) return '$0'
      if (value < 1000) return '$' + value
      value = value.toString().replace(/^0+/, '')
      return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    twoDigitDateYear(value) {
      return DateTime.fromISO(value).toFormat('yy')
    },
  }
}
</script>

<style scoped lang="scss">
  .world-map-container {
    position: relative;
    width: 400px;
    height: 300px;
  }
  .continent-layer {
    width: 400px;
    height: 300px;
    position: absolute;
  }
  .renedian-list {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    .renedian-list-item {
      background: white;
      .renedian-list-button {
        display: none;
      }
    }
    .renedian-list-item:hover {
      .renedian-list-button {
        display: flex;
      }
    }
    .renedian-list-item-empty {
      background: #f5f5f5;
    }
  }
  .ghost {
    background: #ef6c00 !important;
  }
  .badge-top {
    background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fworld-map-160h-10o.png?alt=media&token=c2954802-434c-46b4-b56c-192b3338e849');
    background-size: cover;
    height: 100px;
    .badge-header {
      width: 100%;
      background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Femployee-badge-hole-small.png?alt=media&token=a94173a3-40c8-4516-9c1f-53be87f5c250');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .badge-status {
    line-height: 1rem !important;
  }
  .badge-photo {
    margin-top: -40px;
    border: 2px solid white;
  }
  .add-support-driver {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px dashed #ddd;
    flex-direction: column;
  }
  .vehicle-item {
    border: 1px solid white;
    border-top: 1px solid #e0e0e0;
    .v-list-item__action {
      align-items: center;
    }
    .vehicle-icon {
      color: white;
    }
  }
  .vehicle-item:hover {
    background: #eee;
    .vehicle-icon {
      color: #c4c4c4;
    }
  }
  .vehicle-list {
    border: 3px solid #e0e0e0;
    border-radius: 5px;
  }
</style>