<template>
  <div>
    <v-card v-if="model">
      <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
      <v-card-title>
        <v-btn @click="go('AdminVendorsRestaurants')" icon class="mr-3">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-icon color="orange darken-3" left>mdi-silverware-variant</v-icon>
        <span>{{ model && model.name ? model.name : "Add Restaurant" }}</span>
      </v-card-title>
      <v-card-text>
        <v-alert type="success" v-if="success" dismissible>{{ success }}</v-alert>
        <v-alert type="error" v-if="error" dismissible>{{ error }}</v-alert>

        <!-- Tabs -->
        <v-tabs v-model="tab">
          <v-tab>Details</v-tab>
          <v-tab>Meals</v-tab>
          <v-tab>Notes</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          
          <!-- Details Tab -->
          <v-tab-item>
            <div class="d-flex flex-row">
              <div class="flex-1 mx-3">
                <div class="overline mt-4">Establishment</div>
                <v-text-field label="Name of Establishment" v-model="model.name"></v-text-field>

                <div class="overline mt-4">Currency</div>
                <v-autocomplete
                  :items="currencies"
                  label="Currency"
                  v-model="model.currency"
                  item-text="code"
                  return-object
                  @input="updateExchangeRate()"
                >
                  <template v-slot:item="{ item }">
                    {{ item.code }} - {{ item.name }}
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-if="model.currency && model.currency.code"
                  label="Exchange Rate"
                  v-model="model.currency.exchange"
                  :hint="
                    model.currency.symbol +
                    '1 ' +
                    model.currency.name +
                    ' = C$' +
                    model.currency.exchange +
                    ' Canadian Dollars'
                  "
                ></v-text-field>

                <!-- Photo Upload -->
                <div class="overline mt-4">Photo</div>
                <v-text-field 
                  label="Photo URL" 
                  v-model="model.photo" 
                  prepend-icon="mdi-attachment" 
                  @click:prepend="triggerFileUpload('photo')"
                ></v-text-field>
                <input type="file" ref="photoInput" style="display: none" @change="uploadImage('photo', $event)">
                <v-img
                  contain
                  :src="model.photo"
                  class="text-right"
                  height="150"
                  :style="{ background: model.photo ? 'white' : '#efefef' }"
                ></v-img>
              </div>

              <v-divider vertical></v-divider>

              <div class="flex-1 mx-3">
                <div class="overline mt-4">Location</div>
                <v-text-field label="Address" v-model="model.location.address"></v-text-field>
                <div class="d-flex flex-row">
                  <v-text-field label="City" v-model="model.location.city"></v-text-field>
                  <v-text-field label="Province" v-model="model.location.province"></v-text-field>
                </div>
                <div class="d-flex flex-row">
                  <v-text-field label="Postal Code" v-model="model.location.postal"></v-text-field>
                </div>
                <div class="d-flex flex-row">
                  <v-select
                    label="Country"
                    v-if="model.location.continent"
                    v-model="model.location.country"
                    :items="filtered_countries"
                    item-text="country"
                  ></v-select>
                  <v-select
                    label="Continent"
                    v-model="model.location.continent"
                    :items="continents"
                    item-text="name"
                  ></v-select>
                </div>
                <div class="overline mt-4">Contact</div>
                <v-text-field label="Name" v-model="model.contact.name"></v-text-field>
                <v-text-field label="Phone" v-model="model.contact.phone"></v-text-field>
                <v-text-field label="Mobile" v-model="model.contact.mobile"></v-text-field>
                <v-text-field label="Email" v-model="model.contact.email"></v-text-field>
                <v-text-field label="Website" v-model="model.contact.website"></v-text-field>
              </div>
            </div>
          </v-tab-item>
          
          <!-- Meals Tab -->
          <v-tab-item>
            <div class="d-flex flex-row">
              <div class="flex-1 mx-3">
                <div class="overline mt-4">Meal Offerings</div>
                <v-chip-group
                  multiple
                  v-model="model.meals.index"
                >
                  <v-chip filter outlined>Breakfast</v-chip>
                  <v-chip filter outlined>Lunch</v-chip>
                  <v-chip filter outlined>Dinner</v-chip>
                </v-chip-group>

                <div class="overline mt-4">Meal Rates</div>
                <!-- Meal rates content -->
              </div>
              
              <v-divider vertical></v-divider>
              
              <div class="flex-1 mx-3">
                <div class="overline mt-4">Dietary Offerings</div>
                <v-checkbox
                  v-model="model.dietaryOfferings"
                  v-for="diet in dietaryTypes"
                  :key="diet"
                  :label="diet"
                  :value="diet"
                  multiple
                  hide-details
                ></v-checkbox>
              </div>
            </div>
          </v-tab-item>
          
          <!-- Notes Tab -->
          <v-tab-item>
            <div class="d-flex flex-row">
              <div class="flex-1 mx-3">
                <div class="overline mt-4">Special Notes</div>
                <v-textarea v-model="model.notes" outlined></v-textarea>
              </div>
            </div>
          </v-tab-item>
          
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <v-btn v-if="model.id" small text color="red" @click="deleteDialog = true" :disabled="processing">
          <v-icon left>mdi-delete</v-icon>Delete Restaurant
        </v-btn>
        <v-btn v-else small text color="red" @click="go('AdminVendorsRestaurants')" :disabled="processing">
          <v-icon left>mdi-cancel</v-icon>Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="model.id" color="primary" @click="update()" :loading="processing">
          <v-icon left>mdi-content-save-outline</v-icon>Save Restaurant
        </v-btn>
        <v-btn v-else color="primary" @click="create()" :loading="processing">
          <v-icon left>mdi-content-save-outline</v-icon>Add Restaurant
        </v-btn>
      </v-card-actions>
      
      <!-- Delete Restaurant Dialog -->
      <v-dialog v-model="deleteDialog" width="400">
        <v-card>
          <v-card-title class="headline">Delete Restaurant</v-card-title>
          <v-card-text>
            <v-alert type="error">
              This action is permanent and cannot be undone.
            </v-alert>
            <v-checkbox v-model="deletionConfirmed" color="red" label="I understand the consequences"></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="deleteDialog = false">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" text :disabled="!deletionConfirmed" @click="permanentlyDelete()">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-alert v-else-if="error" type="error">
      {{ error }}
    </v-alert>
  </div>
</template>

<script>
import { getFirestore, doc, addDoc, setDoc, onSnapshot, collection, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import axios from 'axios';

export default {
  name: 'VendorsRestaurant',
  props: ['user'],
  data: () => ({
    tab: 0,
    currencies: [],
    continents: [
      'Africa',
      'Antarctica',
      'Asia',
      'Oceania',
      'Europe',
      'North America',
      'South America',
    ],
    dietaryTypes: [
      'Vegetarian',
      'Vegan',
      'Gluten Free',
      'Dairy Free',
      'Kosher',
      'Halal',
    ],
    countries: [],
    loading: false,
    processing: false,
    success: '',
    error: '',
    deleteDialog: false,
    deletionConfirmed: false,
    model: null,
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.countries = require('@/assets/continents.json');
      this.currencies = require('@/assets/currencies.json');
      let id = this.$route.params.id;
      if (id === 'new') {
        this.model = this.defaultModel();
      } else {
        this.loadRestaurant(id);
      }
    },
    defaultModel() {
      return {
        id: null,
        name: '',
        currency: {
          code: '',
          exchange: 1,
          symbol: '',
        },
        location: {
          address: '',
          city: '',
          province: '',
          postal: '',
          country: '',
          continent: '',
        },
        contact: {
          name: '',
          phone: '',
          mobile: '',
          email: '',
          website: '',
        },
        photo: '',
        meals: {
          index: [],
          breakfast: {
            included: false,
            cost: 0,
          },
          lunch: {
            included: false,
            cost: 0,
          },
          dinner: {
            included: false,
            cost: 0,
          },
        },
        dietaryOfferings: [],
        notes: '',
      };
    },
    loadRestaurant(id) {
      this.loading = true;
      const db = getFirestore();
      const docRef = doc(db, 'restaurants', id);
      onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          this.model = doc.data();
          this.model.id = doc.id;
        } else {
          this.error = 'Restaurant not found.';
        }
        this.loading = false;
      });
    },
    uploadImage(type, event) {
      const file = event.target.files[0];
      const storage = getStorage();
      const storageRef = ref(storage, 'images/' + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          this.processing = true;
        },
        (error) => {
          this.error = 'Failed to upload image: ' + error.message;
          this.processing = false;
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            if (type === 'photo') {
              this.model.photo = downloadURL;
            }
            this.processing = false;
          });
        }
      );
    },
    triggerFileUpload(type) {
      if (type === 'photo') {
        this.$refs.photoInput.click();
      }
    },
    updateExchangeRate() {
      if (this.model.currency && this.model.currency.code) {
        const endpoint =
          'https://v6.exchangerate-api.com/v6/04107731562b261fdf9a93c8/pair/' +
          this.model.currency.code +
          '/CAD';
        axios.get(endpoint).then((response) => {
          if (response.status === 200 && response.data.result === 'success') {
            this.model.currency.exchange = response.data.conversion_rate;
          } else {
            this.model.currency = null;
          }
        });
      }
    },
    create() {
      this.processing = true;
      const db = getFirestore();
      addDoc(collection(db, 'restaurants'), this.model)
        .then((docRef) => {
          this.success = 'Restaurant added successfully.';
          this.model.id = docRef.id;
          this.processing = false;
        })
        .catch((error) => {
          this.error = 'Error adding restaurant: ' + error.message;
          this.processing = false;
        });
    },
    update() {
      this.processing = true;
      const db = getFirestore();
      const docRef = doc(db, 'restaurants', this.model.id);
      setDoc(docRef, this.model)
        .then(() => {
          this.success = 'Restaurant updated successfully.';
          this.processing = false;
        })
        .catch((error) => {
          this.error = 'Error updating restaurant: ' + error.message;
          this.processing = false;
        });
    },
    permanentlyDelete() {
      this.processing = true;
      const db = getFirestore();
      const docRef = doc(db, 'restaurants', this.model.id);
      deleteDoc(docRef)
        .then(() => {
          this.go('AdminVendorsRestaurants');
          this.processing = false;
        })
        .catch((error) => {
          this.error = 'Error deleting restaurant: ' + error.message;
          this.processing = false;
        });
    },
  },
  computed: {
    filtered_countries() {
      return this.countries.filter(
        (country) => country.continent === this.model.location.continent
      );
    },
  },
};
</script>
