<template>
  <v-card>
    <v-progress-linear
      v-if="!loaded.employees"
      color="primary"
      indeterminate
    ></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-account-box</v-icon>
      Employees
      <v-spacer></v-spacer>
      <v-btn color="primary" dark @click="createEmployee">
        <v-icon left>mdi-plus</v-icon>Add Employee
      </v-btn>
    </v-card-title>
    <v-card-text v-if="loaded.employees">
      <v-alert type="success" v-if="success" dismissible>{{ success }}</v-alert>
      <v-alert type="error" v-if="error" dismissible>{{ error }}</v-alert>
      <div class="d-flex flex-row flex-wrap">
        <div v-for="(employee, index) in employees" :key="index">
          <v-card
            class="ma-3 d-flex flex-column"
            width="200"
            height="318"
            :class="employee.active ? '' : 'inactive'"
            :style="{ order: employee.order }"
          >
            <v-sheet
              :color="employee.active ? 'orange darken-3' : 'grey'"
              class="badge-top d-flex flex-column align-center"
            >
              <div class="badge-header pt-2 d-flex justify-end">
                <v-menu offset-y transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon dark v-bind="attrs" v-on="on" class="pa-0 ma-0">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-if="employee.active" @click="edit(employee)" link>
                      <v-icon left>mdi-pencil</v-icon>Edit Record
                    </v-list-item>
                    <v-list-item v-if="employee.active" @click="deactivateEmployee(employee)" link>
                      <v-icon left>mdi-close</v-icon>Deactivate
                    </v-list-item>
                    <v-list-item v-else @click="activateEmployee(employee)" link>
                      <v-icon left>mdi-check</v-icon>Activate
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-sheet>
            <v-sheet color="white" class="d-flex flex-column align-center">
              <v-avatar class="badge-photo" size="80">
                <v-img v-if="employee.photo" :src="employee.photo" contain></v-img>
                <v-img
                  v-else
                  src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Femployee-placeholder.jpeg?alt=media&token=3ba9a1a3-f661-42d2-bc16-875bbcb9646c"
                  contain
                ></v-img>
              </v-avatar>
              <div v-if="employee.name" class="text-h6 grey--text text--darken-2">
                {{ employee.name }}
              </div>
              <div v-else class="grey--text text--darken-2 mt-4">
                {{ employee.email }}
              </div>
              <div v-if="employee.location.country" class="text-caption grey--text text--darken-1">
                {{ employee.location.locale }}, {{ employee.location.country }}
              </div>
            </v-sheet>
            <v-sheet class="flex-1 d-flex flex-column justify-center align-center">
              <div v-if="employee.permissions" class="orange--text text--darken-3 font-weight-bold">
                {{ employee.permissions }}
              </div>
              <div v-else class="grey--text text--darken-3 font-weight-bold">
                No Permissions
              </div>
              <div v-if="!employee.active" class="text-caption grey--text text--darken-1">
                Inactive
              </div>
              <div v-else-if="employee.title" class="text-caption grey--text text--darken-1">
                {{ employee.title }}
              </div>
              <div v-else class="text-caption grey--text text--darken-1">
                Employee
              </div>
            </v-sheet>
            <v-divider></v-divider>
            <div class="d-flex flex-column align-center mt-2">
              <div class="text-caption">Next Tour Sept. 4th</div>
              <div class="overline orange--text text--darken-3 font-bold">
                The Grand Tour
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </v-card-text>
    <v-dialog v-if="globalModel != null" v-model="modifyDialog" width="800">
      <v-card>
        <v-card-title>Modify Employee</v-card-title>
        <v-card-text>
          <div class="d-flex flex-row">
            <div class="flex-1 flex-column">
              <div class="overline">Basic Information</div>
              <v-text-field label="Email" v-model="globalModel.email" :disabled="true"></v-text-field>
              <v-text-field label="Name" v-model="globalModel.name"></v-text-field>
              <v-text-field label="Title" v-model="globalModel.title"></v-text-field>
              <v-menu ref="birthdayMenu" v-model="birthdayMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="globalModel.birthday" label="Birth Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="globalModel.birthday" no-title scrollable :active-picker.sync="activePicker" @input="birthdayMenu = false"></v-date-picker>
              </v-menu>

              <div class="overline">Permissions</div>
              <v-select v-model="globalModel.permissions" :items="permissions" label="Permissions"></v-select>

              <div class="overline">Location</div>
              <div class="d-flex flex-row">
                <v-text-field class="mr-2" label="Locale" v-model="globalModel.location.locale"></v-text-field>
                <v-select class="ml-2" label="Country" v-model="globalModel.location.country" :items="countries" item-text="country" item-value="country"></v-select>
              </div>
              <div class="d-flex flex-row">
                <v-text-field label="Photo" v-model="globalModel.photo"></v-text-field>
                <v-img v-if="globalModel.photo" :src="globalModel.photo" contain height="50" width="0"></v-img>
              </div>

              <div class="overline">Administrative</div>
              <v-checkbox label="Active" v-model="globalModel.active"></v-checkbox>
            </div>
            <v-divider vertical class="mx-3"></v-divider>
            <div class="flex-1 flex-column">
              <div class="overline">Passport</div>
              <v-text-field label="Legal Name" v-model="globalModel.passport.name"></v-text-field>
              <v-text-field label="Nationality" v-model="globalModel.passport.nationality"></v-text-field>
              <v-text-field label="Passport Number" v-model="globalModel.passport.number"></v-text-field>

              <v-menu ref="passportExpiryMenu" v-model="passportExpiryMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="globalModel.passport.expiry" label="Passport Expiry Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="globalModel.passport.expiry" no-title scrollable :active-picker.sync="activePicker" @input="passportExpiryMenu = false"></v-date-picker>
              </v-menu>

              <div class="overline">Costing</div>
              <v-text-field label="Day Rate" v-model="globalModel.rate.amount" prefix="C$"></v-text-field>

              <div class="overline">Certificates & Licenses</div>
              <div v-for="(certification, index) in globalModel.certifications" :key="'certification' + index" class="d-flex flex-row align-center">
                <v-checkbox class="flex-1" :label="certification.name" v-model="certification.active"></v-checkbox>
                <v-menu v-if="certification.active" v-model="certificationMenu[index]" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center">
                      <v-btn small icon v-bind="attrs" v-on="on">
                        <v-icon small>mdi-calendar</v-icon>
                      </v-btn>
                    </div>
                    <div class="d-flex flex-column">
                      <div class="text-caption">Expiration</div>
                      <div v-if="certification.expiry">{{ globalModel.certifications[index].expiry }}</div>
                      <div v-else>No Expiry</div>
                    </div>
                  </template>
                  <v-date-picker v-model="certification.expiry" no-title scrollable @input="certificationMenu[index] = false"></v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="secondary" text @click="modifyDialog = false" :disabled="saving">
            <v-icon left>mdi-close</v-icon>Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="editing" color="primary" dark @click="update()" :loading="saving">
            <v-icon left>mdi-content-save-outline</v-icon>Update {{ model }}
          </v-btn>
          <v-btn v-else color="primary" dark @click="saveNewEmployee()" :loading="saving">
            <v-icon left>mdi-content-save-outline</v-icon>Save {{ model }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deactivateEmployeeDialog" width="400">
      <v-card>
        <v-card-title class="headline">Deactivate Employee</v-card-title>
        <v-card-text>
          <v-alert type="error">
            This action will remove all permissions for this user. They will lose access immediately.
          </v-alert>
          <v-checkbox v-model="deactivationConfirmed" color="red" label="I understand the consequences"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="deactivateEmployeeDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text :disabled="!deactivationConfirmed" @click="proceedWithDeactivation()">Deactivate</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createDialog" width="800">
      <v-card>
        <v-card-title>Create Employee</v-card-title>
        <v-card-text>
          <div class="d-flex flex-row">
            <div class="flex-1 flex-column">
              <div class="overline">Basic Information</div>
              <v-text-field label="Email" v-model="newEmployee.email"></v-text-field>
              <v-text-field label="Password" v-model="newEmployee.password" type="password"></v-text-field>
              <v-text-field label="Name" v-model="newEmployee.name"></v-text-field>
              <v-text-field label="Title" v-model="newEmployee.title"></v-text-field>

              <div class="overline">Permissions</div>
              <v-select v-model="newEmployee.permissions" :items="permissions" label="Permissions"></v-select>

              <div class="overline">Location</div>
              <div class="d-flex flex-row">
                <v-text-field class="mr-2" label="Locale" v-model="newEmployee.location.locale"></v-text-field>
                <v-select class="ml-2" label="Country" v-model="newEmployee.location.country" :items="countries" item-text="country" item-value="country"></v-select>
              </div>
              <v-text-field label="Photo" v-model="newEmployee.photo"></v-text-field>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="secondary" text @click="createDialog = false">
            <v-icon left>mdi-close</v-icon>Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="saveNewEmployee()" :loading="saving">
            <v-icon left>mdi-content-save-outline</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { getFirestore, collection, onSnapshot, doc, setDoc } from "firebase/firestore";
import { merge, cloneDeep } from 'lodash-es'
export default {
  name: "AdminEmployees",
  data() {
    return {
      loaded: {
        employees: false,
      },
      saving: false,
      editing: false,
      deactivationConfirmed: false,
      success: "",
      error: "",
      globalModel: null,
      activePicker: null,
      passportExpiryMenu: false,
      birthdayMenu: false,
      certificationMenu: [false, false, false],
      modifyDialog: false,
      deactivateEmployeeDialog: false,
      createDialog: false,
      newEmployee: this.getDefaultEmployee(), // Use getDefaultEmployee to initialize
      model: "Employee",
      collection: "employees",
      employees: [],
      countries: [],
      permissions: ["Manager", "Guide", "Administration"],
    };
  },
  created() {
    this.countries = require('@/assets/continents.json')
    const db = getFirestore()
    // ---------------------- Get Employees ----------------------
    const employeesCollectionRef = collection(db, 'employees')
    onSnapshot(employeesCollectionRef, (snapshot) => {
      let items = []
      snapshot.forEach((doc) => {
        let item = doc.data()
        item.id = doc.id
        items.push(item)
      })
      this.employees = items
      this.loaded.employees = true
    })
  },
  methods: {
    getDefaultEmployee() {
      return {
        email: "",
        password: "", // Initialize password as part of the default employee
        name: "",
        title: "",
        permissions: "",
        location: {
          locale: "",
          country: "",
        },
        photo: "",
        active: true,
        birthday: "", // Added birthday
        passport: {
          name: "",
          nationality: "",
          number: "",
          expiry: "",
        },
        certifications: [
          { name: "Driver's License", expiry: null, active: false },
          { name: "First Aid Certificate", expiry: null, active: false },
          { name: "Tourist Guide Certificate", expiry: null, active: false },
          { name: "Professional Driving Permit", expiry: null, active: false },
        ],
        rate: {
          currency: "CAD",
          amount: 0,
        },
        order: 0,
        notes: "",
      };
    },
    createEmployee() {
      this.newEmployee = cloneDeep(this.getDefaultEmployee());
      this.createDialog = true;
    },
    async saveNewEmployee() {
      this.saving = true;
      try {
        const endpoint = this.production()
          ? 'https://northamerica-northeast1-renedian-production.cloudfunctions.net/createEmployee' 
          : 'https://northamerica-northeast1-renedian-development.cloudfunctions.net/createEmployee';
        const response = await axios.post(endpoint, {
          email: this.newEmployee.email,
          password: this.newEmployee.password,
          name: this.newEmployee.name,
          title: this.newEmployee.title,
          permissions: this.newEmployee.permissions,
          location: this.newEmployee.location,
          photo: this.newEmployee.photo,
        });
        this.createDialog = false;
        this.success = `Employee added successfully with UID: ${response.data.uid}`;
      } catch (error) {
        console.error("Error adding new employee:", error);
        this.error = `Error adding employee. Please try again.`;
      } finally {
        this.saving = false;
      }
    },
    edit(employee) {
      this.editing = true;
      this.success = false;
      this.error = false;
      // Merge employee data with default to ensure all properties are present
      this.globalModel = merge(cloneDeep(this.getDefaultEmployee()), cloneDeep(employee));
      this.modifyDialog = true;
    },
    activateEmployee(employee) {
      employee.active = true;
      this.saveEmployee(employee);
    },
    deactivateEmployee(employee) {
      console.log("Deactivating employee:", cloneDeep(employee));
      // Merge employee data with default to ensure all properties are present
      this.globalModel = merge(cloneDeep(this.getDefaultEmployee()), cloneDeep(employee));
      this.deactivationConfirmed = false;
      this.deactivateEmployeeDialog = true;
    },
    saveEmployee(employee) {
      this.saving = true;
      this.success = false;
      this.error = false;
      const db = getFirestore();
      const docRef = doc(db, this.collection, employee.id);
      setDoc(docRef, employee)
        .then(() => {
          this.modifyDialog = false;
          this.saving = false;
          this.success = `${this.model} updated successfully.`;
        })
        .catch(() => {
          this.error = `Error updating ${this.model}. Please try again.`;
          this.saving = false;
        });
    },
    update() {
      this.saveEmployee(this.globalModel);
      this.modifyDialog = false;
    },
    proceedWithDeactivation() {
      this.deactivateEmployeeDialog = false;
      this.globalModel.active = false;
      this.globalModel.permissions = null;
      this.saveEmployee(this.globalModel);
    },
  },
  watch: {
    passportExpiryMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    birthdayMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>

<style lang="scss" scoped>
.inactive {
  opacity: 0

.5;
  order: 100;
}
.badge-top {
  background: url("https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fworld-map-160h-10o.png?alt=media&token=c2954802-434c-46b4-b56c-192b3338e849");
  background-size: cover;
  height: 100px;
  .badge-header {
    width: 100%;
    background: url("https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Femployee-badge-hole-small.png?alt=media&token=a94173a3-40c8-4516-9c1f-53be87f5c250");
    background-repeat: no-repeat;
    background-position: center;
  }
}
.badge-photo {
  margin-top: -40px;
  border: 2px solid white;
}
</style>