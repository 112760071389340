import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import VStripeElements from 'v-stripe-elements'
import { environment } from '@/firebase/init'
import VueSignaturePad from 'vue-signature-pad';

import { createVPhoneInput } from 'v-phone-input'; // https://github.com/paul-thebaud/v-phone-input/tree/2.x.x
import 'v-phone-input/dist/v-phone-input.css';
import 'flag-icons/css/flag-icons.min.css';

const vPhoneInput = createVPhoneInput({
  countryIconMode: 'svg',
});

Vue.use(vPhoneInput);
Vue.use(VueSignaturePad);

Vue.config.productionTip = false

Vue.use(vuetify, {
  iconfont: 'mdi' || 'fa'
})

Vue.use(VStripeElements);

Vue.mixin({
  methods: {
    go: (route, params) => {
      let link = router.resolve({ name: route });
      if (link.resolved.matched.length > 0) {
        if (router.currentRoute.name !== route || JSON.stringify(router.currentRoute.params) !== JSON.stringify(params)) {
          router.push({ name: route, params: params }).catch(error => {
            if (error.name != "NavigationDuplicated" && error.name != 'Error') {
              console.log('ERROR', error);
            }
          });
        }
      } else {
        router.push({ name: 'Lost' });
      }
    },
    production: () => {
      return environment === 'production' ? true : false
    },
    development: () => {
      return environment === 'development' ? true : false
    },
  }
})

let app = null

// wait for firebase auth to init before creating the app
const auth = getAuth()
onAuthStateChanged(auth, (user) => {
  if(!app) { // initialize app if not already created
    app = new Vue({
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})