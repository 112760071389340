<template>
  <v-card>
    <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-earth</v-icon>
      {{ model }}s
      <v-spacer></v-spacer>
      <v-btn @click="create()" dark color="orange darken-3"><v-icon left>mdi-plus</v-icon>Add {{  model }}</v-btn>
    </v-card-title>
    <!-- <v-divider></v-divider> -->
    <div class="d-flex flex-row mx-4">
      <v-spacer></v-spacer>
      <v-btn icon @click="view='list'"><v-icon>mdi-view-list</v-icon></v-btn>
      <v-btn icon @click="view='brochure'"><v-icon>mdi-view-module</v-icon></v-btn>
    </div>
    <v-card-text>
      <v-alert type="success" v-if="success" dismissible>{{ success }}</v-alert>
      <v-alert type="error" v-if="error" dismissible>{{ error }}</v-alert>
      <v-alert v-if="!adventures.length" type="info" outlined>
        There are no templates. Click the "Add Template" button to get started.
      </v-alert>
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Search Templates"
        single-line
        hide-details
        filled
        class="mb-4"
      ></v-text-field>
      <!-- ------------ Primary Content Display ------------ -->
      <div v-if="view=='list'" class="d-flex flex-column">
        <div class="adventure-list">
          <draggable :list="adventures" @change="reorder()" ghost-class="adventure-list-ghost">
            <div class="adventure-list-item pa-3 d-flex flex-row align-center" v-for="adventure in adventures" :key="adventure.id">
              <v-icon class="mr-3" small>mdi-drag-vertical</v-icon>
              <div class="d-flex flex-column">
                <div class="font-weight-bold">{{ adventure.details.name }}</div>
                <div>{{ adventure.location.continent }}</div>
              </div>
              <v-spacer></v-spacer>
              <v-btn small icon @click="edit(adventure)"><v-icon class="adventure-list-item-icon" small>mdi-pencil</v-icon></v-btn>
              <v-btn small icon @click="deleteRecord(adventure)"><v-icon class="adventure-list-item-icon" small>mdi-delete</v-icon></v-btn>
            </div>
          </draggable>
        </div>
      </div>
      <div v-if="view=='brochure'" class="d-flex flex-row flex-wrap">
        <div class="brochure" v-for="adventure in filteredAdventures" :key="adventure.id" @click="flip(adventure)" :style="{'background': adventure.display == 'back' ? 'white' : 'grey'}">
          <img class="top overlay" :class="adventure.display == 'front' ? '' : 'flip'" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fbrochure-shiny.png?alt=media&token=8896dcf3-b478-4c25-8072-94cd79b5aaa2">
          <v-img v-if="adventure.display == 'front'" class="overlay" contain :src="adventure.images.brochure"></v-img>
          <div class="top overlay px-4 pl-5 py-3 d-flex flex-column width-100 height-100">
            <div class="d-flex flex-row-reverse">
              <v-menu offset-y transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" class="ma-1" :dark="adventure.display == 'front' ? true : false">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="edit(adventure)" link>
                    <v-icon left>mdi-pencil</v-icon>
                    Edit {{ model }}
                  </v-list-item>
                  <v-list-item @click="deleteRecord(adventure)" link>
                    <v-icon left>mdi-delete</v-icon>
                    Delete {{ model }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <!-- ------------ Front of Brochure ------------ -->
            <div v-if="adventure.display == 'front'" class="d-flex flex-column flex-1">
              <div class="text-h3 white--text font-weight-bold">
                {{ adventure.location.continent }}
              </div>
              <div class="text-caption white--text">
                {{ adventure.location.countries.join(', ') }}
              </div>
              <div class="flex-1 d-flex align-center">
                <div class="text-h4 white--text font-weight-light">{{ adventure.details.name }}</div>
              </div>
              <div class="d-flex flex-row justify-center mb-5">
                <img width="80" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Flogo-white.png?alt=media&token=20344e45-e1a9-4acb-abe4-cce5d55be6e0">
              </div>
            </div>
            <!-- ------------ Back of Brochure ------------ -->
            <div v-else class="d-flex flex-column flex-1 pl-4">
              <div class="text-h5 font-weight-bold mb-4">{{ adventure.details.name }}</div>
              <div class="text-caption">
                <span class="font-weight-bold">Duration: </span> {{ adventure.details.duration }} days
              </div>
              <div class="text-caption">
                <span class="font-weight-bold">Distance: </span> {{ adventure.details.distance }} km
              </div>
              <div class="text-caption">
                <span class="font-weight-bold">Spots: </span> {{ adventure.details.spots }} spots
              </div>
              <div class="text-caption">
                <span class="font-weight-bold">Price: </span> C{{ adventure.details.pricing.base | formatCurrency }}
              </div>
              <!-- <div class="text-caption">
                <span class="font-weight-bold">Crew: </span> {{ adventure.crew.length }}
              </div>
              <div class="text-caption">
                <span class="font-weight-bold">Available Bikes: </span> {{ adventure.vehicles.length }}
              </div> -->
              <div class="text-caption flex-1">
                <span class="font-weight-bold">Excursion: </span> {{ adventure.excursion.available ? 'Excursion Available' : 'No Excursion' }}
              </div>
              <div class="d-flex justify-end mr-2 mb-3">
                <img width="50" height="50" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Flogo.png?alt=media&token=267826ad-7e76-4145-9c5a-ca6de904783a">
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <!-- ------------ Delete Adventure Dialog ------------ -->
    <v-dialog v-model="deleteDialog" width="400">
      <v-card>
        <v-card-title class="headline">Delete {{ model }}</v-card-title>
        <v-card-text>
          <v-alert type="error">
            This action is permanent and cannot be undone. 
          </v-alert>
          <v-checkbox v-model="deletionConfirmed" color="red" label="I understand the consequences"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="deleteDialog=false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text :disabled="!deletionConfirmed" @click="permanentlyDelete()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { getFirestore, doc, setDoc, query, orderBy, onSnapshot, collection, deleteDoc } from 'firebase/firestore'
import Draggable from "vuedraggable"
export default {
  name: 'AdminToursTemplates',
  components: {
    Draggable,
  },
  data: () => ({
    // Common data
    loading: false,
    deletionConfirmed: false,
    deleteDialog: false,
    success: '',
    error: '',
    view: 'brochure',
    // Unique data
    model: 'Template',
    collection: 'templates',
    adventures: [],
    //
    search: '',
  }),
  created() {
    const db = getFirestore()
    const collectionRef = collection(db, this.collection)
    const orderedCollectionRef = query(collectionRef, orderBy('order'))
    onSnapshot(orderedCollectionRef, (snapshot) => {
      this.loading = true
      let items = []
      snapshot.forEach((doc) => {
        let item = doc.data()
        // console.log('ID', doc.id)
        item.id = doc.id
        item.display = 'front'
        items.push(item)
      })
      this.adventures = items
      this.loading = false
    })
  },
  methods: {
    create() {
      this.$router.push({ name: 'AdminToursTemplate', params: { id: 'new' } })
    },
    edit(object) {
      this.$router.push({ name: 'AdminToursTemplate', params: { id: object.id } })
    },
    deleteRecord(object) {
      this.globalModel = object
      this.deletionConfirmed = false
      this.deleteDialog = true
    },
    permanentlyDelete() {
      const db = getFirestore()
      const collectionRef = collection(db, this.collection)
      const docRef = doc(collectionRef, this.globalModel.id)
      deleteDoc(docRef).then(() => {
        this.deleteDialog = false
        this.success = this.model + " deleted successfully."
      }).catch((error) => {
        this.error = "Error deleting " + this.model + ". Please try again."
      })
    },
    flip(adventure) {
      adventure.display = adventure.display == 'front' ? 'back' : 'front'
    },
    reorder() {
      this.adventures.forEach( (adventure, index) => {
        adventure.order = index
      })
      const db = getFirestore()
      const collectionRef = collection(db, 'tenokates')
      // update the order field of each adventure
      this.adventures.forEach( (adventure) => {
        const docRef = doc(collectionRef, adventure.id)
        setDoc(docRef, { order: adventure.order }, { merge: true }).then( () => {
          // this.success = "Order updated successfully."
        }).catch( (error) => {
          // this.error = "Error updating order. Please try again."
        })
      })
    }
  },
  computed: {
    filteredAdventures() {
      return this.adventures.filter(adventure => {
        return adventure.details.name.toLowerCase().includes(this.search.toLowerCase()) ||
          adventure.location.continent.toLowerCase().includes(this.search.toLowerCase()) ||
          adventure.location.countries.join(', ').toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  filters: {
    formatCurrency(value) {
      if (!value) return '$0'
      if (value < 1000) return '$' + value
      value = value.toString().replace(/^0+/, '') // remove any leading zeros
      return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}
</script>
<style lang="scss" scoped>
  .inactive {
    opacity: 0.25;
    order: 1;
  }
  .badge-top {
    background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fworld-map-160h-10o.png?alt=media&token=c2954802-434c-46b4-b56c-192b3338e849');
    background-size: cover;
    height: 100px;
    .badge-header {
      width: 100%;
      background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Femployee-badge-hole-small.png?alt=media&token=a94173a3-40c8-4516-9c1f-53be87f5c250');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .badge-status {
    line-height: 1rem !important;
  }
  .badge-photo {
    margin-top: -40px;
    border: 2px solid white;
  }
  .brochure {
    position: relative;
    width: 320px;
    height: 520px;
    .overlay {
      position: absolute;
    }
    .flip {
      transform: scaleX(-1);
    }
    .top {
      z-index: 1;
    }
  }
  .adventure-list {
    border: 1px solid #efefef;
    border-bottom: none;
    border-radius: 4px;
  }
  .adventure-list-item {
    background: white;
    border-bottom: 1px solid #efefef;
  }
  .adventure-list-ghost {
    background: #efefef;
  }
  .adventure-list-item-icon {
    color: white !important;
  }
  .adventure-list-item:hover .adventure-list-item-icon {
    color: darkgrey !important;
  }
</style>