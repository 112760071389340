<template>
  <v-container>
    <!-- <div class="g-font renedian-red--text mb-6 font-size-22">Tour</div> -->
    <v-progress-linear v-if="loading" indeterminate color="amber"></v-progress-linear>
    <v-alert v-if="error" type="error">{{ error }}</v-alert>
    <v-img
      v-if="loaded"
      class="d-flex align-center white--text rounded-image"
      height="180"
      :src="tour.template.images.banner"
    >
      <div class="d-flex flex-column justify-center pa-6">
        <div class="text-h3 white--text font-weight-bold text-shadow">
          {{ tour.template.details.name }}
        </div>
        <div class="font-size-16 white--text">
          {{ tour.schedule.primary.start | humanDate }} - {{ tour.schedule.primary.finish | humanDate }}
        </div>
      </div>
    </v-img>
    <!-- Sub Menu-->
    <v-tabs v-model="tab" v-if="loaded">
      <v-tab>
        <v-badge v-if="countCompletedActions < countTotalActions" color="red" dot>
          Action Items
        </v-badge>
        <span v-else>Action Items</span>
      </v-tab>
      <v-tab>Getting Ready</v-tab>
      <v-tab>Itinerary</v-tab>
      <v-tab>Guides and Crew</v-tab>
      <v-tab>Manage Booking</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" v-if="loaded">
      <!-- ------------ Action Items ------------ -->
      <v-tab-item>
        <h3 v-if="countCompletedActions < countTotalActions" class="pa-6 pb-0">Required Action Items</h3>
        <v-expansion-panels v-if="countCompletedActions < countTotalActions" v-model="panel" class="pa-6" inset>
          <!-- Passport Information -->
          <v-expansion-panel v-if="!actions.passport.complete">
            <v-expansion-panel-header>
              <v-icon left style="flex: none">mdi-checkbox-blank-outline</v-icon>
              Passport Information
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field
                label="Full Name (as on passport)"
                v-model="actions.passport.fullName"
              ></v-text-field>
              <v-text-field
                label="Passport Number"
                v-model="actions.passport.number"
              ></v-text-field>
              <!-- Date of Birth -->
              <v-menu
                ref="dobMenu"
                v-model="dobMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Date of Birth"
                    v-model="actions.passport.dob"
                    v-on="on"
                    v-bind="attrs"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="actions.passport.dob"
                  @input="dobMenu = false"
                ></v-date-picker>
              </v-menu>
              <!-- Gender -->
              <v-radio-group
                v-model="actions.passport.gender"
                label="Gender"
                row
              >
                <v-radio label="Male" value="Male"></v-radio>
                <v-radio label="Female" value="Female"></v-radio>
              </v-radio-group>
              <!-- Passport Expiry Date -->
              <v-menu
                ref="expiryMenu"
                v-model="expiryMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Passport Expiry Date"
                    v-model="actions.passport.expiryDate"
                    v-on="on"
                    v-bind="attrs"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="actions.passport.expiryDate"
                  @input="expiryMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-text-field
                label="Issuing Authority"
                v-model="actions.passport.issuingAuthority"
              ></v-text-field>
              <!-- Upload Passport Photo -->
              <v-file-input
                v-if="!actions.passport.photoURL"
                label="Upload Passport Photo Page"
                v-model="uploads.passportPhoto.file"
                accept="image/*,application/pdf"
                @change="uploadPassportPhoto"
              ></v-file-input>
              <v-progress-linear
                v-if="uploads.passportPhoto.uploading"
                :value="uploads.passportPhoto.progress"
                color="primary"
              ></v-progress-linear>
              <div v-if="actions.passport.photoURL" class="d-flex flex-row align-center">
                Passport Photo Page:
                <v-btn class="" icon small @click="actions.passport.photoURL = ''; actions.passport.photo = ''; uploads.passportPhoto.file = null">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <v-img
                v-if="actions.passport.photoURL"
                :src="actions.passport.photoURL"
                height="200"
                class="rounded-image"
                contain
                aspect-ratio="1"
                @click="actions.passport.photoURL = ''; actions.passport.photo = ''; uploads.passportPhoto.file = null"
              ></v-img>
              <div class="d-flex flex-row align-center mt-6">
                <div class="flex-1 text-caption red--text">
                  <v-icon v-if="actionErrors.passport" left color="red">mdi-alert</v-icon>
                  {{ actionErrors.passport }}
                </div>
                <v-btn @click="submitPassportInfo" tile color="primary" :loading="actionSaving.passport" :disabled="actionSaving.passport">
                  Save Passport Information
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Driver's License Information (Riders Only) -->
          <v-expansion-panel v-if="!actions.driversLicense.complete && booking.guests[guestIndex].preferences.riding == 'bike'">
            <v-expansion-panel-header>
              <v-icon left style="flex: none">mdi-checkbox-blank-outline</v-icon> 
              Driver's License Information
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field
                label="Full Name (as on driver's license)"
                v-model="actions.driversLicense.fullName"
              ></v-text-field>
              <v-text-field
                label="Driver's License Number"
                v-model="actions.driversLicense.number"
              ></v-text-field>
              <!-- License Expiry Date -->
              <v-menu
                ref="licenseExpiryMenu"
                v-model="licenseExpiryMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Driver's License Expiry Date"
                    v-model="actions.driversLicense.expiryDate"
                    v-on="on"
                    v-bind="attrs"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="actions.driversLicense.expiryDate"
                  @input="licenseExpiryMenu = false"
                ></v-date-picker>
              </v-menu>
              <!-- Driver's License Front Photo -->
              <v-file-input
                v-if="!actions.driversLicense.frontPhotoURL"
                label="Upload Front of Driver's License"
                v-model="uploads.licenseFrontPhoto.file"
                accept="image/*,application/pdf"
                @change="uploadLicenseFrontPhoto"
              ></v-file-input>
              <v-progress-linear
                v-if="uploads.licenseFrontPhoto.uploading"
                :value="uploads.licenseFrontPhoto.progress"
                color="primary"
              ></v-progress-linear>
              <div v-if="actions.driversLicense.frontPhotoURL" class="d-flex flex-row align-center">
                Front of Driver's License:
                <v-btn class="" icon small @click="actions.driversLicense.frontPhotoURL = ''; actions.driversLicense.frontPhotoURL = ''; uploads.licenseFrontPhoto.file = null">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <v-img
                v-if="actions.driversLicense.frontPhotoURL"
                :src="actions.driversLicense.frontPhotoURL"
                height="200"
                class="rounded-image"
                contain
                aspect-ratio="1"
                @click="actions.driversLicense.frontPhotoURL = ''; actions.driversLicense.frontPhotoURL = ''; uploads.licenseFrontPhoto.file = null"
              ></v-img>
              <!-- Driver's License Back Photo -->
              <v-file-input
                v-if="!actions.driversLicense.backPhotoURL"
                label="Upload Back of Driver's License"
                v-model="uploads.licenseBackPhoto.file"
                accept="image/*,application/pdf"
                @change="uploadLicenseBackPhoto"
              ></v-file-input>
              <v-progress-linear
                v-if="uploads.licenseBackPhoto.uploading"
                :value="uploads.licenseBackPhoto.progress"
                color="primary"
              ></v-progress-linear>
              <div v-if="actions.driversLicense.backPhotoURL" class="d-flex flex-row align-center">
                Back of Driver's License:
                <v-btn class="" icon small @click="actions.driversLicense.backPhotoURL = ''; actions.driversLicense.backPhotoURL = ''; uploads.licenseBackPhoto.file = null">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <v-img
                v-if="actions.driversLicense.backPhotoURL"
                :src="actions.driversLicense.backPhotoURL"
                height="200"
                class="rounded-image"
                contain
                aspect-ratio="1"
                @click="actions.driversLicense.backPhotoURL = ''; actions.driversLicense.backPhotoURL = ''; uploads.licenseBackPhoto.file = null"
              ></v-img>
              <div class="d-flex flex-row align-center mt-6">
                <div class="flex-1 text-caption red--text">
                  <v-icon v-if="actionErrors.driversLicense" left color="red">mdi-alert</v-icon>
                  {{ actionErrors.driversLicense }}
                </div>
                <v-btn @click="submitDriversLicenseInfo" tile color="primary" :loading="actionSaving.driversLicense" :disabled="actionSaving.driversLicense">
                  Save Driver's License Information
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Preferences -->
          <v-expansion-panel v-if="!actions.preferences.complete">
            <v-expansion-panel-header>
              <v-icon left style="flex: none">mdi-checkbox-blank-outline</v-icon> 
              Preferences
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- T-Shirt Size -->
              <v-select
                :items="tshirtSizes"
                label="What is your t-shirt size?"
                v-model="actions.preferences.tshirtSize"
              ></v-select>
              <!-- Dietary Requirement -->
              <v-select
                :items="dietaryOptions"
                label="Do you have any specific dietary requirements?"
                v-model="actions.preferences.dietaryRequirement"
              ></v-select>
              <v-text-field
                v-if="actions.preferences.dietaryRequirement === 'Other'"
                label="Specify other dietary requirement"
                v-model="actions.preferences.dietaryOther"
              ></v-text-field>
              <div class="d-flex flex-row align-center mt-6">
                <div class="flex-1 text-caption red--text">
                  <v-icon v-if="actionErrors.preferences" left color="red">mdi-alert</v-icon>
                  {{ actionErrors.preferences }}
                </div>
                <v-btn @click="submitPreferences" tile color="primary" :loading="actionSaving.preferences" :disabled="actionSaving.preferences">
                  Save Preferences
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Medical Information -->
          <v-expansion-panel v-if="!actions.medical.complete">
            <v-expansion-panel-header>
              <v-icon left style="flex: none">mdi-checkbox-blank-outline</v-icon> 
              Medical Information
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Allergies -->
              <v-radio-group
                v-model="actions.medical.hasAllergies"
                label="Do you have any allergies?"
                row
              >
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
              <v-text-field
                v-if="actions.medical.hasAllergies"
                label="Please specify your allergies"
                v-model="actions.medical.allergies"
              ></v-text-field>
              <!-- Medical Needs -->
              <v-radio-group
                v-model="actions.medical.hasMedicalNeeds"
                label="Do you have any specific medical needs (ie. CPAP machine, refrigerated medication, etc.)?"
                row
              >
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
              <v-text-field
                v-if="actions.medical.hasMedicalNeeds"
                label="Please specify your medical needs"
                v-model="actions.medical.medicalNeeds"
              ></v-text-field>
              <div class="d-flex flex-row align-center mt-6">
                <div class="flex-1 text-caption red--text">
                  <v-icon v-if="actionErrors.medical" left color="red">mdi-alert</v-icon>
                  {{ actionErrors.medical }}
                </div>
                <v-btn @click="submitMedicalInfo" tile color="primary" :loading="actionSaving.medical" :disabled="actionSaving.medical">
                  Save Medical Information
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Emergency Contact -->
          <v-expansion-panel v-if="!actions.emergencyContact.complete">
            <v-expansion-panel-header>
              <v-icon left style="flex: none">mdi-checkbox-blank-outline</v-icon> 
              Emergency Contact
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field
                label="Full Name"
                v-model="actions.emergencyContact.name"
              ></v-text-field>
              <v-text-field
                label="Contact Number"
                v-model="actions.emergencyContact.phone"
              ></v-text-field>
              <v-text-field
                label="Email Address"
                v-model="actions.emergencyContact.email"
              ></v-text-field>
              <div class="d-flex flex-row align-center mt-6">
                <div class="flex-1 text-caption red--text">
                  <v-icon v-if="actionErrors.emergencyContact" left color="red">mdi-alert</v-icon>
                  {{ actionErrors.emergencyContact }}
                </div>
                <v-btn @click="submitEmergencyContactInfo" tile color="primary" :loading="actionSaving.emergencyContact" :disabled="actionSaving.emergencyContact">
                  Save Emergency Contact Information
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Waiver -->
          <v-expansion-panel v-if="!actions.waiver.complete">
            <v-expansion-panel-header>
              <v-icon left style="flex: none">mdi-checkbox-blank-outline</v-icon> 
              Liability Waiver
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Waiver></Waiver>
              <!-- Waiver content here -->
              <div class="d-flex flex-row align-center mt-6">
                <div class="flex-1 text-caption red--text">
                  <v-icon v-if="actionErrors.waiver" left color="red">mdi-alert</v-icon>
                  {{ actionErrors.waiver }}
                </div>
                <v-btn @click="submitWaiver" tile color="primary" :loading="actionSaving.waiver" :disabled="actionSaving.waiver">
                  Accept and Submit Waiver
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Completed Actions -->
        <h3 v-if="countCompletedActions > 0" class="pa-6 pb-0">Completed Action Items</h3>
        <v-card v-if="countCompletedActions > 0" class="ma-6">
          <div v-if="actions.passport.complete" class="d-flex flex-row px-6 py-3 align-center completed-action">
            <v-icon left style="flex: none" color="green">mdi-checkbox-marked</v-icon>
            <div class="flex-1">Passport Information</div>
            <v-btn icon @click="actions.passport.complete = false"><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
          <div v-if="actions.driversLicense.complete && booking.guests[guestIndex].preferences.riding == 'bike'" class="d-flex flex-row px-6 py-3 align-center completed-action">
            <v-icon left style="flex: none" color="green">mdi-checkbox-marked</v-icon>
            <div class="flex-1">Driver's License Information</div>
            <v-btn icon @click="actions.driversLicense.complete = false"><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
          <div v-if="actions.preferences.complete" class="d-flex flex-row px-6 py-3 align-center completed-action">
            <v-icon left style="flex: none" color="green">mdi-checkbox-marked</v-icon>
            <div class="flex-1">Preferences</div>
            <v-btn icon @click="actions.preferences.complete = false"><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
          <div v-if="actions.medical.complete" class="d-flex flex-row px-6 py-3 align-center completed-action">
            <v-icon left style="flex: none" color="green">mdi-checkbox-marked</v-icon>
            <div class="flex-1">Medical Information</div>
            <v-btn icon @click="actions.medical.complete = false"><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
          <div v-if="actions.emergencyContact.complete" class="d-flex flex-row px-6 py-3 align-center completed-action">
            <v-icon left style="flex: none" color="green">mdi-checkbox-marked</v-icon>
            <div class="flex-1">Emergency Contact</div>
            <v-btn icon @click="actions.emergencyContact.complete = false"><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
          <div v-if="actions.waiver.complete" class="d-flex flex-row px-6 py-3 align-center completed-action">
            <v-icon left style="flex: none" color="green">mdi-checkbox-marked</v-icon>
            <div class="flex-1">Liability Waiver</div>
            <v-btn icon @click="actions.waiver.complete = false"><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
        </v-card>
      </v-tab-item>
      <!-- ------------ Getting Ready ------------ -->
      <v-tab-item>
        <!-- Existing content -->
      </v-tab-item>
      <!-- ------------ Itinerary ------------ -->
      <v-tab-item>
        <!-- Existing content -->
      </v-tab-item>
      <!-- ------------ Guides & Crew ------------ -->
      <v-tab-item>
        <!-- Existing content -->
      </v-tab-item>
      <!-- Manage Booking -->
      <v-tab-item class="pa-6">
        {{ booking }}
        <v-alert type="info">
          <ul>
            <li>Summary of booking</li>
            <li>Vehicle</li>
            <li>Accommodation</li>
            <li>Dietary</li>
            <li>Make a payment?</li>
          </ul>
        </v-alert>
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar v-model="snackbar" color="green">
      Your changes were saved successfully
    </v-snackbar>
  </v-container>
</template>

<script>
import { getFirestore, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import Waiver from '@/components/Waiver'

export default {
  name: 'PortalTour',
  props: ['user', 'bookings'],
  components: { Waiver },
  data() {
    return {
      // Common
      loading: true,
      tab: 0,
      error: '',
      snackbar: false,
      // Booking and tour data
      bookingIndex: null,
      guestIndex: null,
      booking: null,
      tour: null,
      // Action Items
      panel: null,
      // Date pickers
      dobMenu: false,
      expiryMenu: false,
      licenseExpiryMenu: false,
      // File inputs
      passportPhoto: null,
      driversLicenseFrontPhoto: null,
      driversLicenseBackPhoto: null,
      // Options
      tshirtSizes: [
        'Unisex XS',
        'Unisex S',
        'Unisex M',
        'Unisex L',
        'Unisex XL',
        'Unisex 2XL',
        'Unisex 3XL',
      ],
      dietaryOptions: [
        'No',
        'Vegetarian',
        'Vegan',
        'Gluten-Free',
        'Dairy-Free',
        'Other',
      ],
      // File uploads
      uploads: {
        passportPhoto: {
          file: null,
          uploading: false,
          progress: 0,
        },
        licenseFrontPhoto: {
          file: null,
          uploading: false,
          progress: 0,
        },
        licenseBackPhoto: {
          file: null,
          uploading: false,
          progress: 0,
        },
      },
      // Default actions structure
      defaultActionObject: {
        passport: {
          complete: false,
          fullName: '',
          number: '',
          dob: '',
          gender: '',
          expiryDate: '',
          issuingAuthority: '',
          photo: '',
          photoURL: '',
        },
        driversLicense: {
          complete: false,
          fullName: '',
          number: '',
          expiryDate: '',
          frontPhotoURL: '',
          frontPhoto: '',
          backPhotoURL: '',
          backPhoto: '',
        },
        preferences: {
          complete: false,
          tshirtSize: '',
          dietaryRequirement: '',
          dietaryOther: '',
        },
        medical: {
          complete: false,
          hasAllergies: null,
          allergies: '',
          hasMedicalNeeds: null,
          medicalNeeds: '',
        },
        emergencyContact: {
          complete: false,
          name: '',
          phone: '',
          email: '',
        },
        waiver: {
          complete: false,
          signature: '',
          date: '',
        },
      },
      // Action Errors
      actionErrors: {
        passport: '',
        driversLicense: '',
        preferences: '',
        medical: '',
        emergencyContact: '',
        waiver: '',
      },
      // Action Saving
      actionSaving: {
        passport: false,
        driversLicense: false,
        preferences: false,
        medical: false,
        emergencyContact: false,
        waiver: false,
      }
    };
  },
  methods: {
    initialize(source) {
      this.loading = true
      this.error = ''
      // Check for booking and guest index
      if (this.bookings.length == 0) {
        return;
      }
      // Extract parameters
      this.bookingIndex = parseInt(this.$route.params?.index);
      this.tab = this.$route.params?.tab;
      if (this.bookingIndex >= 0) {        
      } else {
        this.error = 'Invalid booking index';
        return
      }
      // Set Booking
      this.loading = true;
      this.booking = this.bookings[this.bookingIndex];
      if (!this.booking) {
        this.error = 'Booking not found';
        this.loading = false;
        return;
      }
      // Actions are unique to each guest so we locate the guest index first
      // Match this.user.profile.email to booking.guests[0].contact.email or booking.guests[1].contact.email
      this.guestIndex = this.booking.guests.findIndex(guest => guest.contact.email === this.user.profile.email);
      if (this.guestIndex === -1) {
        this.error = 'Guest not found in booking';
        this.loading = false;
        return;
      }
      // Set the default actions object
      if (this.booking.guests[this.guestIndex].actions.length == 0) {
        this.booking.guests[this.guestIndex].actions = cloneDeep(this.defaultActionObject);
      }
      // Load Tour
      const db = getFirestore();
      const docRef = doc(db, 'tours', this.booking.tour.id);
      onSnapshot(docRef, (doc) => {
        this.tour = doc.data();
        this.tour.id = doc.id;
        this.loading = false;
      });
    },
    saveBooking() {
      // ------------ Save Booking to Firestore ------------
      const db = getFirestore();
      const docRef = doc(db, 'bookings', this.booking.id);
      return updateDoc(docRef, this.booking);
    },
    uploadPassportPhoto(file) {
      console.log(file)
      this.uploads.passportPhoto.uploading = true;
      this.uploads.passportPhoto.progress = 0;
      this.actions.passport.photoURL = '';
      this.actions.passport.photo = '';
      if (file == null) {
        this.uploads.passportPhoto.uploading = false;
        return;
      }
      const storage = getStorage();
      const timestamp = new Date().getTime();
      const photoRef = ref(storage, `bookings/${this.booking.id}/guests/${this.guestIndex}/passport-photo/${timestamp}_${file.name}`);

      const task = uploadBytesResumable(photoRef, file);

      task.on(
        'state_changed',
        (snapshot) => {
          this.uploads.passportPhoto.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          this.actionErrors.passport = 'Failed to upload passport photo';
          this.uploads.passportPhoto.uploading = false;
          console.error('Upload error:', error);
        },
        () => {
          getDownloadURL(task.snapshot.ref)
            .then((downloadURL) => {
              this.actions.passport.photoURL = downloadURL;
              this.actions.passport.photo = file.name;
              this.uploads.passportPhoto.uploading = false;
            })
            .catch((error) => {
              this.actionErrors.passport = 'Failed to retrieve download URL';
              this.uploads.passportPhoto.uploading = false;
              console.error('Download URL error:', error);
            });
        }
      );
    },
    uploadLicenseFrontPhoto(file) {
      this.uploads.licenseFrontPhoto.uploading = true;
      this.uploads.licenseFrontPhoto.progress = 0;
      this.actions.driversLicense.frontPhotoURL = '';
      this.actions.driversLicense.frontPhoto = '';
      if (file == null) {
        this.uploads.licenseFrontPhoto.uploading = false;
        return;
      }
      const storage = getStorage();
      const timestamp = new Date().getTime();
      const photoRef = ref(storage, `bookings/${this.booking.id}/guests/${this.guestIndex}/license-photo/${timestamp}_${file.name}`);

      const task = uploadBytesResumable(photoRef, file);

      task.on(
        'state_changed',
        (snapshot) => {
          this.uploads.licenseFrontPhoto.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          this.actionErrors.driversLicense = 'Failed to upload passport photo';
          this.uploads.licenseFrontPhoto.uploading = false;
          console.error('Upload error:', error);
        },
        () => {
          getDownloadURL(task.snapshot.ref)
            .then((downloadURL) => {
              this.actions.driversLicense.frontPhotoURL = downloadURL;
              this.actions.driversLicense.frontPhoto = file.name;
              this.uploads.licenseFrontPhoto.uploading = false;
            })
            .catch((error) => {
              this.actionErrors.driversLicense = 'Failed to retrieve download URL';
              this.uploads.licenseFrontPhoto.uploading = false;
              console.error('Download URL error:', error);
            });
        }
      );
    },
    uploadLicenseBackPhoto(file) {
      this.uploads.licenseBackPhoto.uploading = true;
      this.uploads.licenseBackPhoto.progress = 0;
      this.actions.driversLicense.backPhotoURL = '';
      this.actions.driversLicense.backPhoto = '';
      if (file == null) {
        this.uploads.licenseBackPhoto.uploading = false;
        return;
      }
      const storage = getStorage();
      const timestamp = new Date().getTime();
      const photoRef = ref(storage, `bookings/${this.booking.id}/guests/${this.guestIndex}/license-photo/${timestamp}_${file.name}`);

      const task = uploadBytesResumable(photoRef, file);

      task.on(
        'state_changed',
        (snapshot) => {
          this.uploads.licenseBackPhoto.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          this.actionErrors.driversLicense = 'Failed to upload passport photo';
          this.uploads.licenseBackPhoto.uploading = false;
          console.error('Upload error:', error);
        },
        () => {
          getDownloadURL(task.snapshot.ref)
            .then((downloadURL) => {
              this.actions.driversLicense.backPhotoURL = downloadURL;
              this.actions.driversLicense.backPhoto = file.name;
              this.uploads.licenseBackPhoto.uploading = false;
            })
            .catch((error) => {
              this.actionErrors.driversLicense = 'Failed to retrieve download URL';
              this.uploads.licenseBackPhoto.uploading = false;
              console.error('Download URL error:', error);
            });
        }
      );
    },
    async submitPassportInfo() {
      // 1. Validate Passport data
      this.actionErrors.passport = '';
      this.actionSaving.passport = true;
      if (!this.actions.passport.fullName) {  
        this.actionErrors.passport = 'Full Name is required';
        this.actionSaving.passport = false;
        return;
      }
      if (!this.actions.passport.number) {
        this.actionErrors.passport = 'Passport Number is required';
        this.actionSaving.passport = false;
        return;
      }
      if (!this.actions.passport.dob) {
        this.actionErrors.passport = 'Date of Birth is required';
        this.actionSaving.passport = false;
        return;
      }
      if (!this.actions.passport.gender) {
        this.actionErrors.passport = 'Gender is required';
        this.actionSaving.passport = false;
        return;
      }
      if (!this.actions.passport.expiryDate) {
        this.actionErrors.passport = 'Expiry date is required';
        this.actionSaving.passport = false;
        return;
      }
      if (!this.actions.passport.issuingAuthority) {
        this.actionErrors.passport = 'Issuing Authority is required';
        this.actionSaving.passport = false;
        return;
      }
      if (!this.actions.passport.photoURL) {
        this.actionErrors.passport = 'Passport photo is required';
        this.actionSaving.passport = false;
        return;
      }
      // 2. Save booking
      this.actions.passport.complete = true;
      this.saveBooking().then(() => {
        this.snackbar = true; // show snack bar
        this.actionSaving.passport = false;
      }).catch((error) => {
        this.actionErrors.passport = 'Failed to save booking';
        this.actionSaving.passport = false;
        this.actions.passport.complete = false;
      });
    },
    submitDriversLicenseInfo() {
      this.actionErrors.driversLicense = '';
      this.actionSaving.driversLicense = true;
      // 1. Validate Driver's License data
      if (!this.actions.driversLicense.fullName) {
        this.actionErrors.driversLicense = 'Full Name is required';
        this.actionSaving.driversLicense = false;
        return;
      }
      if (!this.actions.driversLicense.number) {
        this.actionErrors.driversLicense = 'Driver\'s License Number is required';
        this.actionSaving.driversLicense = false;
        return;
      }
      if (!this.actions.driversLicense.expiryDate) {
        this.actionErrors.driversLicense = 'Expiry date is required';
        this.actionSaving.driversLicense = false;
        return;
      }
      if (!this.actions.driversLicense.frontPhotoURL) {
        this.actionErrors.driversLicense = 'Front photo is required';
        this.actionSaving.driversLicense = false;
        return;
      }
      if (!this.actions.driversLicense.backPhotoURL) {
        this.actionErrors.driversLicense = 'Back photo is required';
        this.actionSaving.driversLicense = false;
        return;
      }
      // 2. Save booking
      this.actions.driversLicense.complete = true;
      this.saveBooking().then(() => {
        this.snackbar = true; // show snack bar
        this.actionSaving.driversLicense = false;
      }).catch((error) => {
        this.actionErrors.driversLicense = 'Failed to save booking';
        this.actionSaving.driversLicense = false;
        this.actions.driversLicense.complete = false;
      });
    },
    submitPreferences() {
      this.actionErrors.preferences = '';
      this.actionSaving.preferences = true;
      // 1. Validate Preferences data
      if (!this.actions.preferences.tshirtSize) {
        this.actionErrors.preferences = 'T-Shirt size is required';
        this.actionSaving.preferences = false;
        return;
      }
      if (!this.actions.preferences.dietaryRequirement) {
        this.actionErrors.preferences = 'Dietary requirement is required';
        this.actionSaving.preferences = false;
        return;
      }
      if (this.actions.preferences.dietaryRequirement === 'Other' && !this.actions.preferences.dietaryOther) {
        this.actionErrors.preferences = 'Please specify other dietary requirement';
        this.actionSaving.preferences = false;
        return;
      }
      // 2. Save booking
      this.actions.preferences.complete = true;
      this.saveBooking().then(() => {
        this.snackbar = true; // show snack bar
        this.actionSaving.preferences = false;
      }).catch((error) => {
        this.actionErrors.preferences = 'Failed to save booking';
        this.actionSaving.preferences = false;
        this.actions.preferences.complete = false;
      });
    },
    submitMedicalInfo() {
      this.actionErrors.medical = '';
      this.actionSaving.medical = true;
      // 1. Validate Medical data
      if (this.actions.medical.hasAllergies === null) {
        this.actionErrors.medical = 'Please specify if you have any allergies';
        this.actionSaving.medical = false;
        return;
      }
      if (this.actions.medical.hasAllergies && !this.actions.medical.allergies) {
        this.actionErrors.medical = 'Please specify your allergies';
        this.actionSaving.medical = false;
        return;
      }
      if (this.actions.medical.hasMedicalNeeds === null) {
        this.actionErrors.medical = 'Please specify if you have any medical needs';
        this.actionSaving.medical = false;
        return;
      }
      if (this.actions.medical.hasMedicalNeeds && !this.actions.medical.medicalNeeds) {
        this.actionErrors.medical = 'Please specify your medical needs';
        this.actionSaving.medical = false;
        return;
      }
      // 2. Save booking
      this.actions.medical.complete = true;
      this.saveBooking().then(() => {
        this.snackbar = true; // show snack bar
        this.actionSaving.medical = false;
      }).catch((error) => {
        this.actionErrors.medical = 'Failed to save booking';
        this.actionSaving.medical = false;
        this.actions.medical.complete = false;
      });
    },
    submitEmergencyContactInfo() {
      this.actionErrors.emergencyContact = '';
      this.actionSaving.emergencyContact = true;
      // 1. Validate Emergency Contact data
      if (!this.actions.emergencyContact.name) {
        this.actionErrors.emergencyContact = 'Full Name is required';
        this.actionSaving.emergencyContact = false;
        return;
      }
      if (!this.actions.emergencyContact.phone) {
        this.actionErrors.emergencyContact = 'Contact Number is required';
        this.actionSaving.emergencyContact = false;
        return;
      }
      if (!this.actions.emergencyContact.email) {
        this.actionErrors.emergencyContact = 'Email Address is required';
        this.actionSaving.emergencyContact = false;
        return;
      }
      // 2. Save booking
      this.actions.emergencyContact.complete = true;
      this.saveBooking().then(() => {
        this.snackbar = true; // show snack bar
        this.actionSaving.emergencyContact = false;
      }).catch((error) => {
        this.actionErrors.emergencyContact = 'Failed to save booking';
        this.actionSaving.emergencyContact = false;
        this.actions.emergencyContact.complete = false;
      });
    },
    submitWaiver() {
      this.actionErrors.waiver = '';
      this.actionSaving.waiver = true;
      // 1. Validate Waiver data
      if (!this.actions.waiver.signature) {
        this.actionErrors.waiver = 'Please sign the waiver';
        this.actionSaving.waiver = false;
        return;
      }
      if (!this.actions.waiver.date) {
        this.actionErrors.waiver = 'Please enter the date';
        this.actionSaving.waiver = false;
        return;
      }
      // 2. Save booking
      this.actions.waiver.complete = true;
      this.saveBooking().then(() => {
        this.snackbar = true; // show snack bar
        this.actionSaving.waiver = false;
      }).catch((error) => {
        this.actionErrors.waiver = 'Failed to save booking';
        this.actionSaving.waiver = false;
        this.actions.waiver.complete = false;
      });
    },
  },
  created() {
    this.initialize(1);
  },
  watch: {
    $route(route) {
      this.initialize(2);
    },
    bookings() {
      this.initialize(3);
    },
  },
  computed: {
    actions() {
      return this.booking.guests[this.guestIndex].actions;
    },
    countCompletedActions() {
      let count = 0;
      if (this.actions.passport.complete) count++
      if (this.actions.driversLicense.complete) count++
      if (this.actions.preferences.complete) count++
      if (this.actions.medical.complete) count++
      if (this.actions.emergencyContact.complete) count++
      if (this.actions.waiver.complete) count++
      return count;
    },
    countTotalActions() {
      return this.booking.guests[this.guestIndex].preferences.riding == 'bike' ? 6 : 5;
    },
    loaded() {
      return !this.loading && this.tour && this.booking && this.guestIndex !== null;
    },
    outstandingActions() {
      return Object.keys(this.booking.actions).filter(key => {
        const action = this.booking.actions[key];
        if (typeof action === 'object') {
          return Object.values(action).some(value => !value);
        }
        return !action;
      });
    },
  },
  filters: {
    humanDate(value) {
      return DateTime.fromISO(value).toFormat('MMMM d, yyyy');
    },
    fullHumanDate(value) {
      return DateTime.fromISO(value).toFormat('cccc, LLLL d, yyyy');
    },
  },
};
</script>

<style lang="scss" scoped>
  .rounded-image {
    border-radius: 10px 10px 0 0;
  }
  .text-shadow {
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  }
  .completed-panel {
    border: 1px solid #4caf50;
  }
  .badge-top {
    background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fworld-map-160h-10o.png?alt=media&token=c2954802-434c-46b4-b56c-192b3338e849');
    background-size: cover;
    height: 100px;
    .badge-header {
      width: 100%;
      background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Femployee-badge-hole-small.png?alt=media&token=a94173a3-40c8-4516-9c1f-53be87f5c250');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .badge-status {
    line-height: 1rem !important;
  }
  .badge-photo {
    margin-top: -40px;
    border: 2px solid white;
  }
  .roads-and-weather {
    background: #f7f7f7;
    padding: 11px;
    border-radius: 5px;
    margin: 10px 0;
  }
  .completed-action {
    border-bottom: 1px solid #e0e0e0;
  }
</style>
