<template>
  <v-app>
    <v-app-bar app v-if="!isDesktop">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Admin Console</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-if="employee"
      v-model="drawer"
      app
      :permanent="isDesktop"
      :temporary="!isDesktop"
      class="orange darken-3"
      dark
      width="300"
    >
      <v-list>
        <v-list-item v-if="user" class="pb-2">
          <v-list-item-avatar>
            <v-img v-if="user.profile.avatar" :src="user.profile.avatar"></v-img>
            <v-img v-else contain src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Femployee-placeholder.jpeg?alt=media&token=3ba9a1a3-f661-42d2-bc16-875bbcb9646c"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ employee.name }}</v-list-item-title>
            <div v-if="employee.permissions != 'Manager'" class="text-caption">{{ employee.permissions }}</div>
            <v-menu v-else offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="text-caption">{{ employee.permissions }}</div>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in permissions" :key="index">
                  <v-list-item-title @click="employee.permissions = item">{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <div v-for="(item, index) in menu"  :key="index">
          <v-divider v-if="item.divider"></v-divider>
          <div v-else-if="item.permissions.indexOf(employee.permissions) >= 0">
            <v-list-item v-if="item.children.length == 0" :key="item.title" link @click="go(item.route)" class="ml-2 pl-2" :class="item.route == $route.name ? 'active-item' : ''">
              <v-list-item-icon>
                <v-icon :color="item.route == $route.name ? 'orange darken-4' : 'orange accent-2'">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
              <v-icon v-if="item.route == $route.name">mdi-chevron-right</v-icon>
            </v-list-item>
            <v-list-group v-else :key="index" color="white">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon color="orange accent-2">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="child in item.children" :key="child.title" @click="go(child.route)" link class="ml-4" :class="child.route == $route.name ? 'active-item' : ''">
                <v-list-item-icon>
                  <v-icon :color="child.route == $route.name ? 'orange darken-4' : 'orange darken-1'">{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </div>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block text @click="$emit('logout')">Logout</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="admin-content pa-5">
        <router-view></router-view>
        <div class="text-caption text-center mt-4 grey--text text--darken-1">&copy; {{ new Date().getFullYear() }} Renedian Administration Console (v{{ version }} {{ codename }})</div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { getFirestore, doc, onSnapshot, collection } from 'firebase/firestore'

export default {
  name: 'AdminTemplate',
  props: ['user'],
  data: () => ({
    version: process.env.VUE_APP_VERSION,
    codename: process.env.VUE_APP_CODENAME,
    employee: null,
    drawer: false,
    isDesktop: window.innerWidth >= 1024, // Adjust this breakpoint as needed
    menu: [
      // Dashboard
      { title: 'Dashboard', icon: 'mdi-view-dashboard', route: 'AdminDashboard', permissions: ['Guide', 'Manager'], children: [] },
      // Guide Pages
      { title: 'Current Tour', icon: 'mdi-map-outline', route: 'AdminGuideCurrentTour', permissions: ['Guide'], children: [] },
      { title: 'Upcoming Tours', icon: 'mdi-calendar-clock', route: 'AdminGuideUpcomingTours', permissions: ['Guide'], children: [] },
      { title: 'Profile', icon: 'mdi-account-box', route: 'AdminGuideProfile', permissions: ['Guide'], children: [] },
      // -- divider --
      { divider: true },
      // Admin Guide
      { title: 'Guide', icon: 'mdi-binoculars', permissions: ['Manager'], children: [
        { title: 'Current Tour', icon: 'mdi-map-outline', route: 'AdminGuideCurrentTour', children: [] },
        { title: 'Upcoming Tours', icon: 'mdi-calendar-clock', route: 'AdminGuideUpcomingTours', children: [] },
        { title: 'Profile', icon: 'mdi-account-box', route: 'AdminGuideProfile', children: [] },
      ] },
      // Tours
      { title: 'Tours', icon: 'mdi-earth', permissions: ['Manager'], children: [
        { title: 'Manage Tours', icon: 'mdi-map-outline', route: 'AdminTours' },
        { title: 'Templates', icon: 'mdi-content-copy', route: 'AdminToursTemplates' },
      ] },
      // Bookings
      { title: 'Bookings', icon: 'mdi-book-edit-outline', route: 'AdminBookings', permissions: ['Manager'], children: [] },
      // -- divider --
      { divider: true },
      // Vendors
      { title: 'Vendors', icon: 'mdi-storefront-outline', permissions: ['Manager', 'Administration'], children: [
        // Vehicles
        { title: 'Motorcycles', icon: 'mdi-motorbike', route: 'AdminVendorsVehicles' },
        // Hotels
        { title: 'Hotels', icon: 'mdi-bed-outline', route: 'AdminVendorsHotels' },
        // Restaurants
        { title: 'Restaurants', icon: 'mdi-silverware-variant', route: 'AdminVendorsRestaurants' },
        // Activities
        { title: 'Activities', icon: 'mdi-binoculars', route: 'AdminVendorsActivities' },
      ] },
      // People
      { title: 'People', icon: 'mdi-account-multiple-outline', route: 'AdminCustomers', permissions: ['Manager'], children: [
        { title: 'Customers', icon: 'mdi-account', route: 'AdminCustomers' },
        { title: 'Employees', icon: 'mdi-account-box', route: 'AdminEmployees' },
        { title: 'Leads', icon: 'mdi-account-question-outline', route: 'AdminLeads' },
      ] },
      // -- divider --
      { divider: true },
      // Automations
      { title: 'Automation', icon: 'mdi-cog-outline', permissions: ['Manager'], children: [
        { title: 'Email', icon: 'mdi-email-fast-outline', route: 'AdminAutomationEmail' },
        { title: 'AI', icon: 'mdi-robot-outline', route: 'AdminAutomationAI' },
      ] },
      // Insights
      { title: 'Insights', icon: 'mdi-chart-areaspline', permissions: ['Manager'], children: [
        { title: 'Overview', icon: 'mdi-eye', route: 'AdminInsightsOverview' },
        { title: 'Costing', icon: 'mdi-currency-usd', route: 'AdminInsightsCosting' },
        { title: 'Metrics', icon: 'mdi-chart-timeline-variant-shimmer', route: 'AdminInsightsMetrics' },
      ] },
    ],
    permissions: [
      'Manager',
      'Guide',
      'Administration',
    ],
  }),
  created() {
    this.init()
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    init() {
      if (this.user) {
        // ---------------------- Get Employee ----------------------
        const db = getFirestore()
        const employeesCollectionRef = collection(db, 'employees')
        const employeeDocRef = doc(employeesCollectionRef, this.user.uid)
        onSnapshot(employeeDocRef, (snapshot) => {
          if (snapshot.exists()) {
            this.employee = snapshot.data()
          }
        })
      }
    },
    handleResize() {
      this.isDesktop = window.innerWidth >= 1024 // Adjust this breakpoint as needed
    },
  },
  watch: {
    user() {
      this.init()
    }
  }
}
</script>

<style scoped lang="scss">
.admin-content {
  background: #f3f3f3;
  background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Ftopographic-5.png?alt=media&token=a5587c86-c9e5-4023-9e16-e3f27aa4956c');
  overflow: scroll;
  width: 100%;
  height: 100%;
}
.active {
  /* background: #ffffff77 !important; */
}
.v-list-group--active {
  background: #e96800 !important;
}
.v-list-item--active {
  /* background: #fb8c01 !important; */
  color: white !important;

  // .v-list-group__header__prepend-icon {
  //   color: white !important;
  // }
  // .v-list-item__icon {
  //   color: white !important;
  // }
  // .v-list-item__content {
  //   .v-icon {
  //     color: white !important;
  //   }
  // }
}
.v-list-group__header {
  color: white !important;
  .v-list-group__header__prepend-icon {
    color: white !important;
  }
}
.active-item {
  background: white;
  color: #e65100 !important;
}
</style>
